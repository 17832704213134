import {
  COMPANY_PROFILE_SUCCESS,
  // COMPANY_PROFILE_FAILURE,
  UPDATE_COMPANY_PROFILE,
} from '../actionTypes';
import { getCompanyProfileData } from '../../requests/requests';

const companyProfileRequestSucceeded = (companyProfileData) => ({
  type: COMPANY_PROFILE_SUCCESS,
  companyProfileData,
});

// const companyProfileRequestFailed = (notification) => ({
//   type: COMPANY_PROFILE_FAILURE,
//   notification,
// });

export const updateCompanyProfile = (updatedCompanyProfileData) => ({
  type: UPDATE_COMPANY_PROFILE,
  updatedCompanyProfileData,
});

export const companyProfileAPI = () => async (dispatch) => {
  try {
    const response = await getCompanyProfileData();

    dispatch(companyProfileRequestSucceeded(response.data));
  } catch (error) {
    // dispatch(companyProfileRequestFailed(error.message));
    dispatch(companyProfileRequestSucceeded({
      id: 1,
      company_data: {
        name: 'ІВАНО-ФРАНКІВСЬКА ТОРГОВО-ПРОМИСЛОВА ПАЛАТА',
        edrpou_code: '02944857',
        kind_of_activity: '94.11 Діяльність організацій промисловців і підприємців',
        legal_address: 'м. Івано-Франківськ, вул. Теодора Цьоклера, 9а',
        physical_address: 'м. Івано-Франківськ, вул. Теодора Цьоклера, 9а',
        phone: '+380342523347',
        email: 'info@cci.if.ua',
        website: 'https://cci.if.ua/',
      },
    }));
  } finally {
    console.log('finally');
  }
};
