import { ARTICLE_SUCCESS, ARTICLE_FAILURE } from '../actionTypes';
import { getArticleItem } from '../../requests/requests';

const articleRequestSucceeded = (data) => ({
  type: ARTICLE_SUCCESS,
  data,
});

const articleRequestFailed = (notification) => ({
  type: ARTICLE_FAILURE,
  notification,
});

const articleAPI = (articleId) => async (dispatch) => {
  try {
    const response = await getArticleItem(articleId);

    dispatch(articleRequestSucceeded(response.data));
  } catch (error) {
    dispatch(articleRequestFailed(error.message));
  } finally {
    console.log('finally');
  }
};

export default articleAPI;
