import { SERVICES_SUCCESS, SERVICES_FAILURE } from '../actionTypes';
import { getServices } from '../../requests/requests';

const servicesRequestSucceeded = (serviceData) => ({
  type: SERVICES_SUCCESS,
  serviceData,
});

const servicesRequestFailed = (notification) => ({
  type: SERVICES_FAILURE,
  notification,
});

const servicesAPI = () => (dispatch) => {
  getServices()
    .then((response) => {
      console.log(response.data);
      console.log(response.status);
      console.log(response.statusText);
      console.log(response.headers);
      console.log(response.config);
      console.log(response.request);

      dispatch(servicesRequestSucceeded(response.data));
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.statusText);
        console.log(error.response.headers);
        console.log(error.response.config);
        console.log(error.response.request);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log(error.config);
      }

      console.log(error.message);

      dispatch(servicesRequestFailed(error.message));
    })
    .finally(() => {});
};

export default servicesAPI;
