export const subjectsOfRequest = [
  {
    value: '1',
    labelKey: 'first_subject',
  },
  {
    value: '2',
    labelKey: 'second_subject',
  },
  {
    value: '3',
    labelKey: 'third_subject',
  },
  {
    value: '4',
    labelKey: 'fourth_subject',
  },
  {
    value: '5',
    labelKey: 'fifth_subject',
  },
  {
    value: '6',
    labelKey: 'sixth_subject',
  },
  {
    value: '7',
    labelKey: 'seventh_subject',
  },
];

export const typesOfRequest = [
  {
    value: '1',
    labelKey: 'first_type',
  },
  {
    value: '2',
    labelKey: 'second_type',
  },
  {
    value: '3',
    labelKey: 'third_type',
  },
  {
    value: '4',
    labelKey: 'fourth_type',
  },
  {
    value: '5',
    labelKey: 'fifth_type',
  },
  {
    value: '6',
    labelKey: 'sixth_type',
  },
  {
    value: '7',
    labelKey: 'seventh_type',
  },
];
