/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import './OneImageSlider.scss';

export default function OneImageSlider({
  slides,
  isRenderItem,
  namespace,
}) {
  const { t } = useTranslation(namespace);

  let items;

  if (isRenderItem) {
    items = slides.map((slide) => ({
      renderItem: () => (
        <div className="render-item">
          <div className="image-dimming" />
          <img
            src={slide.imageUrl}
            alt="slideImage"
          />
          <div className="slide-info">
            <h2 className="heading-two">
              {t(`main.slides.${slide.titleKey}.heading_bottom`)}
            </h2>
            <p className="description-bottom">
              {t(`main.slides.${slide.titleKey}.description_bottom`)}
            </p>
          </div>
        </div>
      ),
    }));
  } else {
    items = slides.map((slide) => ({
      original: slide,
    }));
  }

  return (
    <ImageGallery
      items={items}
      autoPlay
      lazyLoad
      slideDuration={1000}
      slideInterval={10000}
      showNav={false}
      showBullets
      showPlayButton={false}
      showFullscreenButton={false}
    />
  );
}
