/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CallOrderForm from '../../CallOrderForm/CallOrderForm';
import './InternationalCooperation.scss';

export default function InternationalCooperation() {
  const { t, i18n } = useTranslation(['service_pages', 'buttons']);

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant',
    });
  }, []);
  const ldJson = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Івано-Франківська торгово-промислова палата',
    url: 'https://cci.if.ua/',
    logo: 'https://cci.if.ua/static/media/logo_small.088e05e0446873d0c8dfb27546b82194.svg',
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+380342523347',
      contactType: 'customer service',
      areaServed: 'UA',
      availableLanguage: 'Ukrainian',
    },
    sameAs: [
      'https://www.facebook.com/cci.if.ua/',
      'https://www.instagram.com/if.cci.ua/',
    ],
  };
  return (
    <section className="InternationalCooperation">
      <Helmet>
        <title>
          Які переваги Міжнародної Співпраці для Експорту? | Івано-Франківська ТПП
        </title>
        <meta
          name="description"
          content="Дізнайтеся, як Івано-Франківська Торгово-промислова палата допомагає
          у розширенні експортних можливостей через міжнародну співпрацю та маркетингові дослідження."
        />
        <script type="application/ld+json">
          {JSON.stringify(ldJson)}
        </script>
      </Helmet>
      <div className="wrapper">
        <div className="international-cooperation-content">
          <div className="international-cooperation-content-top-row">
            <a
              className="previous-page-link"
              href={
                i18n.languages[0] === 'en'
                  ? '/en/'
                  : '/'
              }
            >
              {t('previous_page_title')}
            </a>
            <div className="international-cooperation-content-top-row__right-side">
              <h1 className="heading-one">
                {t('heading.international_cooperation')}
              </h1>
              <p className="description">
                {t('description')}
              </p>
              <div className="types-of-service">
                <ul>
                  <li>
                    експортне маркетингове дослідження;
                  </li>
                  <li>
                    міжнародні програми для бізнесу;
                  </li>
                  <li>
                    організація міжнародних торгових місій та візова підтримка;
                  </li>
                  <li>
                    переклад та засвідчення перекладів печаткою Івано-Франківської торгово-промислової палати (ТПП);
                  </li>
                  <li>
                    пошук партнерів для економічної співпраці.
                  </li>
                </ul>
              </div>
              <div className="list-of-required-documents">
                <p className="paragraph paragraph_bold-font">
                  Документи, необхідні для реєстрації торгової марки:
                </p>
                <ul>
                  <li>
                    заповнена заява для оформлення документів на ТМ;
                  </li>
                  <li>
                    копії паспорта та ідентифікаційного коду заявника (для фізичних осіб) або установчі документи для
                    юридичних осіб;
                  </li>
                  <li>
                    логотип в форматі pdf;
                  </li>
                  <li>
                    контактний телефон заявника;
                  </li>
                  <li>
                    емейл заявника;
                  </li>
                  <li>
                    поштова адреса для листування;
                  </li>
                  <li>
                    роз'яснення виду діяльності чи найменування товарів для ТМ.
                  </li>
                </ul>
              </div>
              <div className="if-tpp-advice">
                <p className="paragraph">
                  Ми вважаємо, що сучасному підприємцю, який хоче вийти на іноземні ринки, для орієнтації в ринковому
                  середовищі недостатньо особистої інтуїції, чужих суджень, висловів, думок та висновків. Йому потрібна
                  інформація щодо існуючих і очікуваних ринкових проблем в країні збуту та способів їх розв'язання.
                </p>
                <p className="paragraph paragraph_bold-font">
                  #Порада від Івано-Франківської ТПП
                </p>
                <p className="paragraph">
                  Пропонуємо Вам скористатися популярною послугою Івано-Франківської ТПП – експортним маркетинговим
                  дослідженням ринку.
                </p>
                <p className="paragraph paragraph_bold-font">
                  Даний сервіс включає аналіз:
                </p>
                <ul>
                  <li>
                    SWOT-аналіз;
                  </li>
                  <li>
                    аналіз ринку;
                  </li>
                  <li>
                    аналіз сезонності продажів;
                  </li>
                  <li>
                    державних нормативних актів щодо виробу;
                  </li>
                  <li>
                    аналіз спеціальної сертифікації;
                  </li>
                  <li>
                    аналіз потенційного покупця;
                  </li>
                  <li>
                    аналіз конкурентів;
                  </li>
                  <li>
                    аналіз адаптації товару для обраного ринку;
                  </li>
                  <li>
                    аналіз цінової політики.
                  </li>
                </ul>
                <p className="paragraph paragraph_bold-font">
                  Крім вищеназваного Ви отримуєте наступні переваги:
                </p>
                <ul>
                  <li>
                    моніторинг актуального бізнес-середовища, в якому працює підприємство;
                  </li>
                  <li>
                    оцінка існуючої бізнес-стратегії та розробка нової;
                  </li>
                  <li>
                    аналіз цінової політики та стратегії конкурентних зовнішніх операторів ринку;
                  </li>
                  <li>
                    to do list для підготовки товару до експорту (адаптація, сертифікація тощо);
                  </li>
                  <li>
                    календар виставково-ярмаркових заходів та надання рекомендації щодо участі.
                  </li>
                </ul>
                <p className="paragraph paragraph_bold-font">
                  Також Івано-Франківська ТПП надає послуги митного оформлення та необхідної сертифікації походження
                  Вашого продукту для експорту.
                </p>
              </div>
            </div>
          </div>
          <h2 className="heading-two heading-two_form">
            {t('call_order_form_heading')}
          </h2>
          <p className="description">
            {t('call_order_form_description')}
          </p>
          <CallOrderForm />
        </div>
      </div>
    </section>
  );
}
