/* eslint-disable default-param-last */
import {
  ARTICLES_SUCCESS,
  ARTICLES_FAILURE,
  ARTICLES_PER_PAGE,
  ARTICLES_PAGE_COUNT,
  ARTICLES_CURRENT_PAGE,
} from '../actionTypes';

const initialState = {
  articleList: {},
  perPage: null,
  pageCount: null,
  currentPage: null,
  isReady: false,
  notification: 'article_list_loading',
};

const articlesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ARTICLES_SUCCESS:
      return {
        ...state,
        articleList: action.articleList,
        isReady: true,
        notification: '',
      };
    case ARTICLES_FAILURE:
      return {
        ...state,
        articleList: {},
        perPage: null,
        pageCount: null,
        currentPage: null,
        isReady: false,
        notification: action.notification,
      };
    case ARTICLES_PER_PAGE:
      return {
        ...state,
        perPage: action.perPage,
      };
    case ARTICLES_PAGE_COUNT:
      return {
        ...state,
        pageCount: action.pageCount,
      };
    case ARTICLES_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage,
      };
    default:
      return state;
  }
};

export default articlesReducer;
