/* eslint-disable no-dupe-keys */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useField, ErrorMessage } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import Select from 'react-select';
import {
  minWidth1600px,
  maxWidth1600px,
  maxWidth1440px,
  maxWidth1280px,
  maxWidth1024px,
  maxWidth834px,
  maxWidth601px,
} from './changeableStyles';
import './SelectElement.scss';

export default function SelectElement({
  label,
  placeholder,
  name,
  value,
  options,
  onChange,
  isSearchable = false,
  isMulti = false,
  isClearable = false,
  isDisabled = false,
  // menuIsOpen = true,
}) {
  const [adaptiveStyles, setAdaptiveStyles] = useState({});
  const [isPrimary, setIsPrimary] = useState(false);
  const { pathname } = useLocation();
  const [, meta] = useField(name);

  const htmlFor = `${name}-${uuidv4()}`;
  const className = classNames('select-container', { invalid: meta.touched && meta.error });
  const finalStyles = {
    ...adaptiveStyles,
    container: (baseStyles) => ({
      ...baseStyles,
      width: '100%',
    }),
    valueContainer: (baseStyles) => ({
      ...baseStyles,
      padding: '0',
    }),
    clearIndicator: (baseStyles) => ({
      ...baseStyles,
      padding: '0 4px',
      ':hover': {
        opacity: '0.7',
      },
    }),
    indicatorSeparator: (baseStyles) => ({
      ...baseStyles,
      margin: '4px 0',
      backgroundColor: '#3D4910',
    }),
    dropdownIndicator: (baseStyles) => ({
      ...baseStyles,
      padding: '0 4px',
      ':hover': {
        opacity: '0.7',
      },
    }),
    menuList: (baseStyles) => ({
      ...baseStyles,
      maxHeight: '',
      padding: '0',
    }),
  };

  useEffect(() => {
    const availableWindowWidth = document.documentElement.clientWidth;

    if (availableWindowWidth > 1600) {
      setAdaptiveStyles(minWidth1600px);
    } else if (availableWindowWidth <= 1600 && availableWindowWidth > 1440) {
      setAdaptiveStyles(maxWidth1600px);
    } else if (availableWindowWidth <= 1440 && availableWindowWidth > 1280) {
      setAdaptiveStyles(maxWidth1440px);
    } else if (availableWindowWidth <= 1280 && availableWindowWidth > 1024) {
      setAdaptiveStyles(maxWidth1280px);
    } else if (availableWindowWidth <= 1024 && availableWindowWidth > 834) {
      setAdaptiveStyles(maxWidth1024px);
    } else if (availableWindowWidth <= 834 && availableWindowWidth > 601) {
      setAdaptiveStyles(maxWidth834px);
    } else if (availableWindowWidth <= 601) {
      setAdaptiveStyles(maxWidth601px);
    }
  }, []);

  useEffect(() => {
    if (
      pathname === '/en/'
      || pathname === '/'
      || pathname.startsWith('/activities/en/')
      || pathname.startsWith('/activities/')
    ) {
      setIsPrimary(true);
    }
  }, [pathname]);

  return (
    <div className="select-wrapper">
      <label htmlFor={htmlFor}>
        {label}
      </label>
      <Select
        className={className}
        placeholder={placeholder}
        name={name}
        value={value}
        options={options}
        onChange={onChange}
        isSearchable={isSearchable}
        isMulti={isMulti}
        isClearable={isClearable}
        isDisabled={isDisabled}
        // menuIsOpen={menuIsOpen}
        styles={finalStyles}
        classNames={{
          control: () => (isPrimary ? 'primaryControl' : 'secondaryControl'),
          indicatorsContainer: () => 'indicatorsContainer',
          menu: () => (isPrimary ? 'primaryMenu' : 'secondaryMenu'),
        }}
      />
      <ErrorMessage
        className="warning"
        component="span"
        name={name}
      />
    </div>

  );
}
