/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useField, ErrorMessage } from 'formik';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import PhoneInput from 'react-phone-input-2';
import edit from '../../../../assets/icons/actions/edit.svg';
import closeGreen from '../../../../assets/icons/actions/close_green.svg';
import 'react-phone-input-2/lib/style.css';
import './PhoneInputElement.scss';

export default function PhoneInputElement({
  label,
  setPhoneParameters,
  currentDialCode,
  setCurrentDialCode,
  formattedPhoneNumber,
  setFormattedPhoneNumber,
  source,
  isDisabled = false,
  isEditable = false,
  isNecessaryToCloseAllEditors,
  setIsNecessaryToCloseAllEditors,
  ...props
}) {
  const [isInputFieldDisabled, setIsInputFieldDisabled] = useState(isDisabled);
  const [isVisited, setIsVisited] = useState(false);
  const [dropdownListWidth, setDropdownListWidth] = useState();
  const [field, meta, helpers] = useField(props);
  const { isRegistrationVisible } = useSelector((state) => state.modalWindows.registration);

  const htmlFor = `${field.name}-${uuidv4()}`;
  const className = classNames({ invalid: meta.touched && meta.error });

  const toggleActivity = () => {
    if (isInputFieldDisabled) {
      setIsInputFieldDisabled(false);
    } else {
      helpers.setValue(meta.initialValue);
      helpers.setTouched(false);
      setCurrentDialCode(undefined);
      setFormattedPhoneNumber(meta.initialValue);
      setIsInputFieldDisabled(true);
      setIsVisited(false);
    }
  };

  const phoneLengthEstimation = (...args) => {
    const minPhoneLengthLimit = args[1].dialCode.length;
    const requiredPhoneLength = args[1].format.match(/[.]/g).length;

    setPhoneParameters(minPhoneLengthLimit, requiredPhoneLength);

    if (source === 'company_profile') {
      const digits = formattedPhoneNumber.slice(1).split('');
      const characters = args[1].format.split('');
      const reg = /[.]/g;

      for (const digit of digits) {
        const result = reg.exec(args[1].format);

        if (result) {
          characters[result.index] = digit;
        }
      }

      if (!isVisited && formattedPhoneNumber.slice(1) !== currentDialCode) {
        setIsVisited(true);
        setCurrentDialCode(args[1].dialCode);
        setFormattedPhoneNumber(`${characters.join('')}`);
      }
    }
  };

  const phoneKeyDownHandler = (e) => {
    if (field.value === '+380' && e.key === '0') {
      e.preventDefault();
    }
  };

  const phoneChangeHandler = (...args) => {
    if (args[3].length <= args[1].format.length) {
      helpers.setValue(`+${args[0]}`);
      setFormattedPhoneNumber(args[3]);
    }

    if (args[1].dialCode !== currentDialCode) {
      helpers.setValue(`+${args[1].dialCode}`);
      setCurrentDialCode(args[1].dialCode);
      setFormattedPhoneNumber(`+${args[1].dialCode}`);
    }
  };

  const phoneBlurHandler = (...args) => {
    field.onBlur(args[0]);
  };

  useEffect(() => {
    const phoneInputContainers = document.querySelectorAll('.phone-input-container');
    let visiblePhoneInputContainer;

    if (isRegistrationVisible) {
      visiblePhoneInputContainer = phoneInputContainers[1];
    } else {
      visiblePhoneInputContainer = phoneInputContainers[0];
    }

    const { width, height } = getComputedStyle(visiblePhoneInputContainer);

    setDropdownListWidth(`${parseFloat(width) - parseFloat(height)}px`);
  }, [isRegistrationVisible]);

  useEffect(() => {
    if (isNecessaryToCloseAllEditors) {
      setCurrentDialCode(undefined);
      setFormattedPhoneNumber(meta.value);
      setIsNecessaryToCloseAllEditors(false);
      setIsInputFieldDisabled(true);
      setIsVisited(false);
    }
  }, [isNecessaryToCloseAllEditors]);

  return (
    <div className="phone-input-wrapper">
      <div className="title">
        <label htmlFor={htmlFor}>
          {label}
        </label>
        {
          isEditable && (
            isInputFieldDisabled
              ? (
                <img
                  src={edit}
                  alt="edit"
                  aria-hidden="true"
                  onClick={toggleActivity}
                />
              )
              : (
                <img
                  src={closeGreen}
                  alt="closeGreen"
                  aria-hidden="true"
                  onClick={toggleActivity}
                />
              )
          )
        }
      </div>
      <PhoneInput
        value={formattedPhoneNumber}
        preferredCountries={['ua', 'pl', 'de', 'cz', 'it', 'es', 'gb', 'bg', 'fr', 'ro', 'sk']}
        excludeCountries={['by', 'ir', 'kp', 'ru']}
        inputProps={{
          id: htmlFor, value: formattedPhoneNumber, autoComplete: 'off', ...props,
        }}
        disabled={isInputFieldDisabled}
        enableLongNumbers
        countryCodeEditable={false}
        containerClass="phone-input-container"
        inputClass={className}
        buttonClass="flag-container"
        dropdownStyle={{
          marginTop: '1px', width: dropdownListWidth,
        }}
        onFocus={phoneLengthEstimation}
        onKeyDown={phoneKeyDownHandler}
        onChange={phoneChangeHandler}
        onBlur={phoneBlurHandler}
      />
      <ErrorMessage
        className="warning"
        component="span"
        name={field.name}
      />
    </div>
  );
}
