import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ContactForm from '../ContactForm/ContactForm';
import './Forms.scss';

export default function Forms() {
  const { t } = useTranslation('landing_page', { keyPrefix: 'forms' });

  useEffect(() => {
    const header = document.querySelector('.Header');
    const formsAnchor = document.querySelector('#forms');

    formsAnchor.style.top = `-${header.offsetHeight}px`;
  }, []);

  return (
    <section className="Forms">
      <div id="forms" className="anchor" />
      <div className="wrapper">
        <div className="forms-content">
          <h2 className="heading-two">
            {t('heading')}
          </h2>
          <p className="description">
            {t('description')}
          </p>
          <ContactForm />
        </div>
      </div>
    </section>
  );
}
