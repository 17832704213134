import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import communityIndicators from '../../../constants/communityIndicators';
import CommunityIndicatorItem from '../CommunityIndicatorItem/CommunityIndicatorItem';
import './CommunityIndicatorList.scss';

export default function CommunityIndicatorList() {
  return (
    <div className="community-indicator-list">
      {
        communityIndicators.map((communityIndicator) => (
          <CommunityIndicatorItem
            key={uuidv4()}
            data={communityIndicator}
          />
        ))
      }
    </div>
  );
}
