import {
  ARTICLES_SUCCESS,
  ARTICLES_FAILURE,
  ARTICLES_PER_PAGE,
  ARTICLES_PAGE_COUNT,
  ARTICLES_CURRENT_PAGE,
} from '../actionTypes';
import { getArticleList } from '../../requests/requests';

const articlesRequestSucceeded = (articleList) => ({
  type: ARTICLES_SUCCESS,
  articleList,
});

const articlesRequestFailed = (notification) => ({
  type: ARTICLES_FAILURE,
  notification,
});

const setArticlesPerPage = (perPage) => ({
  type: ARTICLES_PER_PAGE,
  perPage,
});

const setArticlesPageCount = (pageCount) => ({
  type: ARTICLES_PAGE_COUNT,
  pageCount,
});

export const setArticlesCurrentPage = (currentPage) => ({
  type: ARTICLES_CURRENT_PAGE,
  currentPage,
});

export const articlesAPI = () => (dispatch, getState) => {
  const {
    articleList,
    perPage,
    pageCount,
    currentPage,
    isReady,
  } = { ...getState().articles };

  if (!perPage && !pageCount && !currentPage && !isReady) {
    getArticleList(6, 1)
      .then((response) => {
        dispatch(articlesRequestSucceeded({ 1: response.data.articles }));
        dispatch(setArticlesPerPage(6));
        dispatch(setArticlesPageCount(response.data.page_count));
        dispatch(setArticlesCurrentPage(1));
      })
      .catch((error) => dispatch(articlesRequestFailed(error.message)))
      .finally(() => {});
  } else if (!articleList[currentPage]) {
    getArticleList(perPage, currentPage)
      .then((response) => {
        const newState = {
          ...articleList,
          [`${currentPage}`]: response.data.articles,
        };

        dispatch(articlesRequestSucceeded(newState));
      })
      .catch((error) => dispatch(articlesRequestFailed(error.message)))
      .finally(() => {});
  }
};
