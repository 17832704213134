/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Button from '../../ReusableElements/Buttons/Button/Button';
import CallOrderForm from '../../CallOrderForm/CallOrderForm';
import './CertificatesBarcodesATAcarnets.scss';

export default function CertificatesBarcodesATAcarnets() {
  const { t, i18n } = useTranslation(['service_pages', 'buttons']);

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant',
    });
  }, []);
  const ldJson = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Івано-Франківська торгово-промислова палата',
    url: 'https://cci.if.ua/',
    logo: 'https://cci.if.ua/static/media/logo_small.088e05e0446873d0c8dfb27546b82194.svg',
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+380342523347',
      contactType: 'customer service',
      areaServed: 'UA',
      availableLanguage: 'Ukrainian',
    },
    sameAs: [
      'https://www.facebook.com/cci.if.ua/',
      'https://www.instagram.com/if.cci.ua/',
    ],
  };
  return (
    <section className="CertificatesBarcodesATAcarnets">
      <Helmet>
        <title>
          Як отримати сертифікати, штрих-коди та карнети ATA? | Івано-Франківська ТПП
        </title>
        <meta
          name="description"
          content="Дізнайтеся про процес отримання сертифікатів походження, оформлення
          штрих-кодів та карнетів ATA у Івано-Франківській Торгово-промисловій палаті."
        />
        <script type="application/ld+json">
          {JSON.stringify(ldJson)}
        </script>
      </Helmet>
      <div className="wrapper">
        <div className="certificates-barcodes-ATA-carnets-content">
          <div className="certificates-barcodes-ATA-carnets-content-top-row">
            <a
              className="previous-page-link"
              href={
                i18n.languages[0] === 'en'
                  ? '/en/'
                  : '/'
              }
            >
              {t('previous_page_title')}
            </a>
            <div className="certificates-barcodes-ATA-carnets-content-top-row__right-side">
              <h1 className="heading-one">
                {t('heading.certificates_barcodes_ATA_carnets')}
              </h1>
              <p className="description">
                {t('description')}
              </p>
              <div className="types-of-service">
                <ul>
                  <li>
                    сертифікати походження: certificate of origin, загальної форми (англійською мовою), загальної форми
                    (російською мовою);
                  </li>
                  <li>
                    оформлення документів для штрихового кодування;
                  </li>
                  <li>
                    карнети ATA.
                  </li>
                </ul>
              </div>
              <div className="buttons">
                <Button
                  priority="primary"
                  link="#"
                  download
                >
                  {t('buttons:download_the_form')}
                </Button>
                <Button
                  priority="secondary"
                  link="#"
                  target="_blank"
                >
                  {t('buttons:view_the_sample')}
                </Button>
              </div>
              <div className="list-of-issued-certificates">
                <p className="paragraph">
                  Сертифікат про походження товару – це офіційний документ, який підтверджує, що експортований товар
                  має походження з України. Даний документ дає право на отримання преференції (пільги при імпорті) в
                  країні ввезення.
                </p>
                <p className="paragraph paragraph_bold-font">
                  Івано-Франківська торгово-промислова палата (ТПП) видає сертифікати походження наступних форм:
                </p>
                <ul>
                  <li>
                    сертифікат походження (certificate of origin) – підтверджує країну, у якій товар був повністю
                    вироблений або підданий достатній обробці. Вимагається, як правило, митними органами країни, що
                    імпортує, з метою здійснення тарифних і нетарифних мір регулювання ввозу товару на митну територію
                    відповідної країни;
                  </li>
                  <li>
                    сертифікат походження загальної форми (англійською мовою) – звичайна форма сертифікату, яку видають
                    на всі товари при експорті в країни, з якими не обумовлені правила визначення країни походження
                    товарів. Також видають у разі, коли товар не має преференційного доступу на ринок країни імпорту та
                    у випадках відсутності або тимчасового припинення пільг на українські товари;
                  </li>
                  <li>
                    сертифікат походження загальної форми (російською мовою) – звичайна форма сертифікату, яку видають
                    при експорті до країн СНД;
                  </li>
                  <li>
                    сертифікат походження форми СТ-1 (російською мовою) – видають на товари, що експортуються до країн
                    СНД та задовольняють вимогам Правил визначення країни походження товарів (крім РФ), затверджених
                    рішенням Ради глав урядів СНД від 24.09.1993 р. та Правил визначення країни походження товарів,
                    затверджених рішенням Ради глав урядів СНД від 30.11.2000 р.;
                  </li>
                  <li>
                    сертифікат форми EUR-1 серії M (англійською мовою) – для експорту в Республіку Північна Македонія
                    видають згідно положень Угоди про вільну торгівлю між Республікою Північна Македонія та Україною
                    на підставі Висновку про походження товару, який є невід'ємною частиною сертифіката;
                  </li>
                  <li>
                    сертифікат походження форми У-1 (українською мовою) – застосовують в межах митної території України
                    в разі потреби підтвердження українського походження товарів, які знаходяться в обігу в межах
                    митної території України;
                  </li>
                  <li>
                    сертифікат походження для засвідчення товарів, що експортуються до Мексиканських Сполучених Штатів
                    та яким на території Мексики надаються преференції;
                  </li>
                  <li>
                    сертифікат походження послуг – для підтвердження факту виконання послуги українським підприємством
                    в межах або за межами митної території України;
                  </li>
                  <li>
                    сертифікат вільного продажу (certificate of free sale) – підтверджує, що продукт вільно без
                    обмежень продається на території країни-експортера.
                  </li>
                </ul>
                <p className="paragraph paragraph_bold-font">
                  Для того, щоб отримати більше інформації, зв'яжіться з нами зручним для Вас способом: тел. +38 (0342) 523346, 523347; e-mail: info@cci.if.ua.
                </p>
              </div>
            </div>
          </div>
          <h2 className="heading-two heading-two_form">
            {t('call_order_form_heading')}
          </h2>
          <p className="description">
            {t('call_order_form_description')}
          </p>
          <CallOrderForm />
        </div>
      </div>
    </section>
  );
}
