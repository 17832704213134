const menuItems = [
  {
    id: 0,
    name: 'Користувачі',
    open: false,
    subitems: [],
  },
  {
    id: 1,
    name: 'Реклама',
    open: false,
    subitems: [],
  },
  {
    id: 2,
    name: 'Слайдер',
    open: false,
    subitems: [],
  },
  {
    id: 3,
    name: 'Керівництво',
    open: false,
    subitems: [],
  },
  {
    id: 4,
    name: 'Статті',
    open: false,
    subitems: [],
  },
  {
    id: 5,
    name: 'Події',
    open: false,
    subitems: [],
  },
  {
    id: 6,
    name: 'Послуги',
    open: false,
    subitems: [],
  },
  {
    id: 7,
    name: 'Компанії',
    open: false,
    subitems: [],
  },
  {
    id: 8,
    name: 'Сповіщення',
    open: false,
    subitems: [
      {
        id: 0,
        name: 'Головна',
        open: false,
        subitems: [],
      },
      {
        id: 1,
        name: 'Послуги',
        open: false,
        subitems: [],
      },
      {
        id: 2,
        name: 'Статті клієнтів',
        open: false,
        subitems: [
          {
            id: 0,
            name: 'Пропоновані',
            open: false,
            subitems: [],
          },
          {
            id: 1,
            name: 'Коментарі',
            open: false,
            subitems: [],
          },
        ],
      },
      {
        id: 3,
        name: 'Замовити дзвінок',
        open: false,
        subitems: [],
      },
    ],
  },
];

export default menuItems;
