const arbitrationCourtDocuments = [
  {
    titleKey: 'first_document',
  },
  {
    titleKey: 'second_document',
  },
  {
    titleKey: 'third_document',
  },
  {
    titleKey: 'fourth_document',
  },
];

export default arbitrationCourtDocuments;
