import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { sendCallOrderForm } from '../../requests/requests';
import { showInfoMessage } from '../../store/actions/modalWindowsActions';
import { typesOfRequest } from '../../constants/optionsOfSelects';
import OrdinaryInput from '../ReusableElements/FormElemets/OrdinaryInput/OrdinaryInput';
import PhoneInputElement from '../ReusableElements/FormElemets/PhoneInputElement/PhoneInputElement';
import SelectElement from '../ReusableElements/FormElemets/SelectElement/SelectElement';
import Submit from '../ReusableElements/Buttons/Submit/Submit';
import InfoMessage from '../ModalWindows/InfoMessage/InfoMessage';
import './CallOrderForm.scss';

export default function CallOrderForm() {
  const { t } = useTranslation(['form_error_messages', 'form_fields', 'selects', 'buttons', 'info_messages']);
  const [selectedTypeOfRequest, setSelectedTypeOfRequest] = useState(null);
  const [minPhoneLengthLimit, setMinPhoneLengthLimit] = useState();
  const [requiredPhoneLength, setRequiredPhoneLength] = useState();
  const [currentDialCode, setCurrentDialCode] = useState('380');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('+380');
  const dispatch = useDispatch();
  const {
    isInfoMessageVisible,
    contentKey,
  } = useSelector((state) => state.modalWindows.infoMessage);

  const callOrderSchema = Yup.object({
    full_name: Yup
      .string()
      .required(t('call_order_form.full_name.required'))
      .matches(/^[a-zа-щєіїьюя'"`\s-]+$/i, t('call_order_form.full_name.matches'))
      .min(2, t('call_order_form.full_name.min')),
    phone: Yup
      .string()
      .min(minPhoneLengthLimit + 2, t('call_order_form.phone.min'))
      .length(requiredPhoneLength + 1, t('call_order_form.phone.length')),
    type_of_request: Yup
      .string()
      .nonNullable(t('call_order_form.type_of_request.non_nullable')),
  });

  const transform = (enteredValue) => {
    const trimmedValue = enteredValue
      .replace(/^['"`\s-]+/, '')
      .replace(/['"`\s-]+$/, '');
    const valueWithDeletedExtraCharacters = trimmedValue
      .replace(/[a-zа-щєіїьюя]['"`\s-]*\s+['"`\s-]*[a-zа-щєіїьюя]/gi, (str) => `${str[0]} ${str[str.length - 1]}`)
      .replace(/['"`-]+/g, (str) => str[0]);
    const partsOfValue = valueWithDeletedExtraCharacters.split(' ');
    const valueForRegistration = partsOfValue
      .map((str) => str[0].toUpperCase() + str.slice(1).toLowerCase())
      .join(' ')
      .replace(/-[a-zа-щєіїьюя]/gi, (str) => str.toUpperCase());

    return valueForRegistration;
  };

  const sendRequest = (values, actions) => {
    const formData = { ...values };

    formData.full_name = transform(formData.full_name);
    formData.theme = formData.type_of_request; // <---!!!

    delete formData.type_of_request;

    sendCallOrderForm(formData)
      .then((response) => {
        if (response.status === 200) {
          actions.resetForm({
            touched: {},
            submitCount: 0,
          });

          setSelectedTypeOfRequest(null);
          setCurrentDialCode('380');
          setFormattedPhoneNumber('+380');
          dispatch(showInfoMessage('call_order_form'));
        }

        console.log(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const setPhoneParameters = (minLengthLimit, requiredLength) => {
    setMinPhoneLengthLimit(minLengthLimit);
    setRequiredPhoneLength(requiredLength);
  };

  const types = typesOfRequest.map(({ value, labelKey }) => ({
    value,
    label: t(`selects:types_of_request.${labelKey}`),
  }));

  const selectTypeOfRequest = (selectedOption, formik) => {
    formik.setFieldTouched('type_of_request');
    formik.setFieldValue('type_of_request', selectedOption && selectedOption.value);

    setSelectedTypeOfRequest(selectedOption);
  };

  return (
    <>
      <Formik
        initialValues={{
          full_name: '',
          phone: '+380',
          type_of_request: null,
        }}
        validationSchema={callOrderSchema}
        onSubmit={sendRequest}
      >
        {(formik) => (
          <Form className="call-order-form">
            <div className="call-order-form-fields">
              <OrdinaryInput
                label={t('form_fields:call_order_form.full_name.label')}
                placeholder={t('form_fields:call_order_form.full_name.placeholder')}
                name="full_name"
                type="text"
                maxLength={40}
              />
              <PhoneInputElement
                label={t('form_fields:call_order_form.phone.label')}
                placeholder={t('form_fields:call_order_form.phone.placeholder')}
                name="phone"
                type="tel"
                setPhoneParameters={setPhoneParameters}
                currentDialCode={currentDialCode}
                setCurrentDialCode={setCurrentDialCode}
                formattedPhoneNumber={formattedPhoneNumber}
                setFormattedPhoneNumber={setFormattedPhoneNumber}
              />
              <SelectElement
                label={t('selects:types_of_request.label')}
                placeholder={t('selects:types_of_request.placeholder')}
                name="type_of_request"
                value={selectedTypeOfRequest}
                options={types}
                onChange={(selectedOption) => selectTypeOfRequest(selectedOption, formik)}
                isClearable
              />
            </div>
            <Submit
              priority="primary"
              isDisabled={formik.isSubmitting}
            >
              {t('buttons:order')}
            </Submit>
          </Form>
        )}
      </Formik>
      {
        isInfoMessageVisible && contentKey === 'call_order_form' && (
          <InfoMessage>
            {t('info_messages:call_order_form')}
          </InfoMessage>
        )
      }
    </>
  );
}
