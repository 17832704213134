import firstSlideLarge from '../assets/images/landing-main-slides/01_slide_large.png';
import firstSlideMedium from '../assets/images/landing-main-slides/01_slide_medium.png';
import firstSlideSmall from '../assets/images/landing-main-slides/01_slide_small.png';
import secondSlideLarge from '../assets/images/landing-main-slides/02_slide_large.png';
import secondSlideMedium from '../assets/images/landing-main-slides/02_slide_medium.png';
import secondSlideSmall from '../assets/images/landing-main-slides/02_slide_small.png';
import thirdSlideLarge from '../assets/images/landing-main-slides/03_slide_large.png';
import thirdSlideMedium from '../assets/images/landing-main-slides/03_slide_medium.png';
import thirdSlideSmall from '../assets/images/landing-main-slides/03_slide_small.png';
import fourthSlideLarge from '../assets/images/landing-main-slides/04_slide_large.png';
import fourthSlideMedium from '../assets/images/landing-main-slides/04_slide_medium.png';
import fourthSlideSmall from '../assets/images/landing-main-slides/04_slide_small.png';

const availableWindowWidth = document.documentElement.clientWidth;

let firstSlide;
let secondSlide;
let thirdSlide;
let fourthSlide;

if (availableWindowWidth > 834) {
  firstSlide = firstSlideLarge;
  secondSlide = secondSlideLarge;
  thirdSlide = thirdSlideLarge;
  fourthSlide = fourthSlideLarge;
} else if (availableWindowWidth <= 834 && availableWindowWidth > 430) {
  firstSlide = firstSlideMedium;
  secondSlide = secondSlideMedium;
  thirdSlide = thirdSlideMedium;
  fourthSlide = fourthSlideMedium;
} else if (availableWindowWidth <= 430) {
  firstSlide = firstSlideSmall;
  secondSlide = secondSlideSmall;
  thirdSlide = thirdSlideSmall;
  fourthSlide = fourthSlideSmall;
}

const landingMainSlides = [
  {
    titleKey: 'first_slide',
    imageUrl: firstSlide,
  },
  {
    titleKey: 'second_slide',
    imageUrl: secondSlide,
  },
  {
    titleKey: 'third_slide',
    imageUrl: thirdSlide,
  },
  {
    titleKey: 'fourth_slide',
    imageUrl: fourthSlide,
  },
];

export default landingMainSlides;
