/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import defaultCompanyLogo from '../../../assets/images/logos/TPP/default-company-logo.svg';
import './VIPCompanyItem.scss';

export default function VIPCompanyItem({ data }) {
  const { t } = useTranslation('landing_page', { keyPrefix: 'our_partners' });

  return (
    <div className="vip-company-list__item">
      <img
        className="vip-company-list__logo"
        src={data.logo ? data.logo.url : defaultCompanyLogo}
        alt={data.name}
        loading="lazy"
      />
      <div className="vip-company-list__info">
        <p className="primary-paragraph">
          {data.name}
        </p>
        <p className="secondary-paragraph">
          {`${t('manager')}: ${data.ceo}`}
        </p>
      </div>
    </div>
  );
}
