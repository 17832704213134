import React from 'react';
import logoLoader from '../../../assets/images/logos/TPP/logo_loader.svg';
import './PageLoader.scss';

export default function PageLoader() {
  return (
    <div className="PageLoader">
      <img
        className="logo-loader"
        src={logoLoader}
        alt="logoLoader"
      />
    </div>
  );
}
