/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React from 'react';
import './Navbar.scss';

export default function Navbar({ role, onExit }) {
  return (
    <section className="Navbar">
      <div className="NavbarInfo">{role}</div>
      <div
        className="NavbarExit"
        onClick={onExit}
      >
        Вийти
      </div>
    </section>
  );
}
