/* eslint-disable no-dupe-keys */
export const minWidth1600px = {
  control: (baseStyles) => ({
    ...baseStyles,
    minHeight: '26px',
    padding: '10px 15px',
    border: '1px solid #3D4910',
    borderRadius: '24px',
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    ':hover': {
      border: '1px solid #677C1A',
      cursor: 'pointer',
    },
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    margin: '0',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '26px',
    color: 'rgba(61, 73, 16, 0.7)',
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    margin: '0',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '26px',
    color: '#3D4910',
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    top: '-webkit-calc(100% - 20px)',
    top: '-moz-calc(100% - 20px)',
    top: '-o-calc(100% - 20px)',
    top: '-ms-calc(100% - 20px)',
    top: 'calc(100% - 20px)',
    margin: '0',
    padding: '20px 0',
    borderRadius: '15px',
    backgroundColor: '#FFFFFF',
    boxShadow: '1px 0 14px rgba(125, 131, 102, 0.2)',
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    padding: '10px 40px',
    backgroundColor: state.isSelected ? '#F1F1F1' : 'none',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '26px',
    color: '#3D4910',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#F1F1F1',
    },
  }),
};

export const maxWidth1600px = {
  control: (baseStyles) => ({
    ...baseStyles,
    minHeight: '24px',
    padding: '10px 15px',
    border: '1px solid #3D4910',
    borderRadius: '23px',
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    ':hover': {
      border: '1px solid #677C1A',
      cursor: 'pointer',
    },
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    margin: '0',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '17px',
    lineHeight: '24px',
    color: 'rgba(61, 73, 16, 0.7)',
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    margin: '0',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '17px',
    lineHeight: '24px',
    color: '#3D4910',
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    top: '-webkit-calc(100% - 17.5px)',
    top: '-moz-calc(100% - 17.5px)',
    top: '-o-calc(100% - 17.5px)',
    top: '-ms-calc(100% - 17.5px)',
    top: 'calc(100% - 17.5px)',
    margin: '0',
    padding: '17.5px 0',
    borderRadius: '15px',
    backgroundColor: '#FFFFFF',
    boxShadow: '1px 0 14px rgba(125, 131, 102, 0.2)',
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    padding: '9px 40px',
    backgroundColor: state.isSelected ? '#F1F1F1' : 'none',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '17px',
    lineHeight: '24px',
    color: '#3D4910',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#F1F1F1',
    },
  }),
};

export const maxWidth1440px = {
  control: (baseStyles) => ({
    ...baseStyles,
    minHeight: '23px',
    padding: '10px 15px',
    border: '1px solid #3D4910',
    borderRadius: '22.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    ':hover': {
      border: '1px solid #677C1A',
      cursor: 'pointer',
    },
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    margin: '0',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '23px',
    color: 'rgba(61, 73, 16, 0.7)',
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    margin: '0',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '23px',
    color: '#3D4910',
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    top: '-webkit-calc(100% - 17.5px)',
    top: '-moz-calc(100% - 17.5px)',
    top: '-o-calc(100% - 17.5px)',
    top: '-ms-calc(100% - 17.5px)',
    top: 'calc(100% - 17.5px)',
    margin: '0',
    padding: '17.5px 0',
    borderRadius: '15px',
    backgroundColor: '#FFFFFF',
    boxShadow: '1px 0 14px rgba(125, 131, 102, 0.2)',
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    padding: '8.5px 35px',
    backgroundColor: state.isSelected ? '#F1F1F1' : 'none',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '23px',
    color: '#3D4910',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#F1F1F1',
    },
  }),
};

export const maxWidth1280px = {
  control: (baseStyles) => ({
    ...baseStyles,
    minHeight: '22px',
    padding: '10px 15px',
    border: '1px solid #3D4910',
    borderRadius: '22px',
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    ':hover': {
      border: '1px solid #677C1A',
      cursor: 'pointer',
    },
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    margin: '0',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '15px',
    lineHeight: '22px',
    color: 'rgba(61, 73, 16, 0.7)',
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    margin: '0',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '15px',
    lineHeight: '22px',
    color: '#3D4910',
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    top: '-webkit-calc(100% - 15px)',
    top: '-moz-calc(100% - 15px)',
    top: '-o-calc(100% - 15px)',
    top: '-ms-calc(100% - 15px)',
    top: 'calc(100% - 15px)',
    margin: '0',
    padding: '15px 0',
    borderRadius: '15px',
    backgroundColor: '#FFFFFF',
    boxShadow: '1px 0 14px rgba(125, 131, 102, 0.2)',
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    padding: '8px 35px',
    backgroundColor: state.isSelected ? '#F1F1F1' : 'none',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '15px',
    lineHeight: '22px',
    color: '#3D4910',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#F1F1F1',
    },
  }),
};

export const maxWidth1024px = {
  control: (baseStyles) => ({
    ...baseStyles,
    minHeight: '20px',
    padding: '10px 15px',
    border: '1px solid #3D4910',
    borderRadius: '21px',
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    ':hover': {
      border: '1px solid #677C1A',
      cursor: 'pointer',
    },
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    margin: '0',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: 'rgba(61, 73, 16, 0.7)',
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    margin: '0',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#3D4910',
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    top: '-webkit-calc(100% - 15px)',
    top: '-moz-calc(100% - 15px)',
    top: '-o-calc(100% - 15px)',
    top: '-ms-calc(100% - 15px)',
    top: 'calc(100% - 15px)',
    margin: '0',
    padding: '15px 0',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    boxShadow: '1px 0 14px rgba(125, 131, 102, 0.2)',
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    padding: '7px 30px',
    backgroundColor: state.isSelected ? '#F1F1F1' : 'none',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#3D4910',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#F1F1F1',
    },
  }),
};

export const maxWidth834px = {
  control: (baseStyles) => ({
    ...baseStyles,
    minHeight: '19px',
    padding: '10px 15px',
    border: '1px solid #3D4910',
    borderRadius: '20.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    ':hover': {
      border: '1px solid #677C1A',
      cursor: 'pointer',
    },
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    margin: '0',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '19px',
    color: 'rgba(61, 73, 16, 0.7)',
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    margin: '0',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '19px',
    color: '#3D4910',
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    top: '-webkit-calc(100% - 12.5px)',
    top: '-moz-calc(100% - 12.5px)',
    top: '-o-calc(100% - 12.5px)',
    top: '-ms-calc(100% - 12.5px)',
    top: 'calc(100% - 12.5px)',
    margin: '0',
    padding: '12.5px 0',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    boxShadow: '1px 0 14px rgba(125, 131, 102, 0.2)',
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    padding: '6px 30px',
    backgroundColor: state.isSelected ? '#F1F1F1' : 'none',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '19px',
    color: '#3D4910',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#F1F1F1',
    },
  }),
};

export const maxWidth601px = {
  control: (baseStyles) => ({
    ...baseStyles,
    minHeight: '18px',
    padding: '10px 15px',
    border: '1px solid #3D4910',
    borderRadius: '20px',
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    ':hover': {
      border: '1px solid #677C1A',
      cursor: 'pointer',
    },
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    margin: '0',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '18px',
    color: 'rgba(61, 73, 16, 0.7)',
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    margin: '0',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#3D4910',
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    top: '-webkit-calc(100% - 12.5px)',
    top: '-moz-calc(100% - 12.5px)',
    top: '-o-calc(100% - 12.5px)',
    top: '-ms-calc(100% - 12.5px)',
    top: 'calc(100% - 12.5px)',
    margin: '0',
    padding: '12.5px 0',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    boxShadow: '1px 0 14px rgba(125, 131, 102, 0.2)',
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    padding: '5px 25px',
    backgroundColor: state.isSelected ? '#F1F1F1' : 'none',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#3D4910',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#F1F1F1',
    },
  }),
};
