export const SHOW_HOTLINE = 'SHOW_HOTLINE';
export const HIDE_HOTLINE = 'HIDE_HOTLINE';
export const SHOW_INFO_MESSAGE = 'SHOW_INFO_MESSAGE';
export const HIDE_INFO_MESSAGE = 'HIDE_INFO_MESSAGE';
export const SHOW_REGISTRATION = 'SHOW_REGISTRATION';
export const HIDE_REGISTRATION = 'HIDE_REGISTRATION';
export const SHOW_LOG_IN = 'SHOW_LOG_IN';
export const HIDE_LOG_IN = 'HIDE_LOG_IN';
export const SHOW_UPLOAD_FILES = 'SHOW_UPLOAD_FILES';
export const HIDE_UPLOAD_FILES = 'HIDE_UPLOAD_FILES';

export const SHOW_HAMBURGER_MENU = 'SHOW_HAMBURGER_MENU';
export const HIDE_HAMBURGER_MENU = 'HIDE_HAMBURGER_MENU';
export const SHOW_MAIN_DROPDOWN_LIST = 'SHOW_MAIN_DROPDOWN_LIST';
export const HIDE_MAIN_DROPDOWN_LIST = 'HIDE_MAIN_DROPDOWN_LIST';
export const SHOW_DOCUMENTS_DROPDOWN_LIST = 'SHOW_DOCUMENTS_DROPDOWN_LIST';
export const HIDE_DOCUMENTS_DROPDOWN_LIST = 'HIDE_DOCUMENTS_DROPDOWN_LIST';
export const SHOW_PROPOSALS_FOR_CCI_DROPDOWN_LIST = 'SHOW_PROPOSALS_FOR_CCI_DROPDOWN_LIST';
export const HIDE_PROPOSALS_FOR_CCI_DROPDOWN_LIST = 'HIDE_PROPOSALS_FOR_CCI_DROPDOWN_LIST';
export const SHOW_SEARCH_FORM = 'SHOW_SEARCH_FORM';
export const HIDE_SEARCH_FORM = 'HIDE_SEARCH_FORM';
export const SHOW_LANGUAGE_DROPDOWN_LIST = 'SHOW_LANGUAGE_DROPDOWN_LIST';
export const HIDE_LANGUAGE_DROPDOWN_LIST = 'HIDE_LANGUAGE_DROPDOWN_LIST';

export const SEARCH_QUERY = 'SEARCH_QUERY';
export const SEARCH_RESULTS_SUCCESS = 'SEARCH_RESULTS_SUCCESS';
export const SEARCH_RESULTS_FAILURE = 'SEARCH_RESULTS_FAILURE';
export const SEARCH_RESULTS_PER_PAGE = 'SEARCH_RESULTS_PER_PAGE';
export const SEARCH_RESULTS_PAGE_COUNT = 'SEARCH_RESULTS_PAGE_COUNT';
export const SEARCH_RESULTS_CURRENT_PAGE = 'SEARCH_RESULTS_CURRENT_PAGE';

export const ARTICLES_SUCCESS = 'ARTICLES_SUCCESS';
export const ARTICLES_FAILURE = 'ARTICLES_FAILURE';
export const ARTICLES_PER_PAGE = 'ARTICLES_PER_PAGE';
export const ARTICLES_PAGE_COUNT = 'ARTICLES_PAGE_COUNT';
export const ARTICLES_CURRENT_PAGE = 'ARTICLES_CURRENT_PAGE';

export const ARTICLE_SUCCESS = 'ARTICLE_SUCCESS';
export const ARTICLE_FAILURE = 'ARTICLE_FAILURE';

export const SERVICES_SUCCESS = 'SERVICES_SUCCESS';
export const SERVICES_FAILURE = 'SERVICES_FAILURE';

export const EVENTS_SUCCESS = 'EVENTS_SUCCESS';
export const EVENTS_FAILURE = 'EVENTS_FAILURE';
export const EVENTS_PER_PAGE = 'EVENTS_PER_PAGE';
export const EVENTS_PAGE_COUNT = 'EVENTS_PAGE_COUNT';
export const EVENTS_CURRENT_PAGE = 'EVENTS_CURRENT_PAGE';

export const EVENT_SUCCESS = 'EVENT_SUCCESS';
export const EVENT_FAILURE = 'EVENT_FAILURE';

export const MANAGERS_SUCCESS = 'MANAGERS_SUCCESS';
export const MANAGERS_FAILURE = 'MANAGERS_FAILURE';

export const COMPANIES_SUCCESS = 'COMPANIES_SUCCESS';
export const COMPANIES_FAILURE = 'COMPANIES_FAILURE';
export const COMPANIES_PER_PAGE = 'COMPANIES_PER_PAGE';
export const COMPANIES_PAGE_COUNT = 'COMPANIES_PAGE_COUNT';
export const COMPANIES_CURRENT_PAGE = 'COMPANIES_CURRENT_PAGE';

export const VIP_COMPANIES_SUCCESS = 'VIP_COMPANIES_SUCCESS';
export const VIP_COMPANIES_FAILURE = 'VIP_COMPANIES_FAILURE';

export const COMPANY_PROFILE_SUCCESS = 'COMPANY_PROFILE_SUCCESS';
export const COMPANY_PROFILE_FAILURE = 'COMPANY_PROFILE_FAILURE';
export const UPDATE_COMPANY_PROFILE = 'UPDATE_COMPANY_PROFILE';
