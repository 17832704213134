import React, { useEffect, useState } from 'react';
import './UsersList.scss';
import User from '../User/User';
import { getUsers } from '../../../requests/requests';

export default function UsersList() {
  const [userData, setUserData] = useState();

  useEffect(() => {
    getUsers()
      .then((response) => {
        console.log(response.data);
        setUserData(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  return (
    <section className="UsersList">
      <table className="users-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Назва компанії</th>
            <th>Електронна пошта</th>
            <th>Статус</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {userData && userData.map((user) => (
            <User
              key={user.id}
              id={user.id}
              name={user.company_name}
              email={user.email}
              status={user.status}
            />
          ))}
        </tbody>
      </table>
    </section>
  );
}
