/* eslint-disable default-param-last */
import { VIP_COMPANIES_SUCCESS, VIP_COMPANIES_FAILURE } from '../actionTypes';

const initialState = {
  vipCompanyData: [],
  isReady: false,
  notification: 'VIP_company_list_loading',
};

const vipCompaniesReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIP_COMPANIES_SUCCESS:
      return {
        ...state,
        vipCompanyData: action.vipCompanyData,
        isReady: true,
        notification: '',
      };
    case VIP_COMPANIES_FAILURE:
      return {
        ...state,
        vipCompanyData: [],
        isReady: false,
        notification: action.notification,
      };
    default:
      return state;
  }
};

export default vipCompaniesReducer;
