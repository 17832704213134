/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import CompanyInfo from '../CompanyInfo/CompanyInfo';
import { getCompanies } from '../../../../requests/requests';
import './CompanyList.scss';

export default function CompanyList() {
  const [companyData, setCompanyData] = useState();

  useEffect(() => {
    getCompanies()
      .then((response) => {
        console.log(response.data);
        setCompanyData(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  return (
    <section className="CompanyList">
      <table className="company-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Назва</th>
            <th>CEO</th>
            <th>Сфера діяльності</th>
            <th>ЄДЕРПОУ</th>
            <th>Email</th>
            <th>Телефон</th>
            <th>Статус</th>
          </tr>
        </thead>
        <tbody>
          {companyData && companyData.map((company) => (
            <CompanyInfo
              key={company.id}
              id={company.id}
              name={company.name}
              ceo={company.ceo}
              activity={company.activity}
              codEderpou={company.edrpou_code}
              email={company.email}
              phone={company.phone}
              status={company.status}
            />
          ))}
        </tbody>
      </table>
    </section>
  );
}
