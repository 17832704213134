import {
  SEARCH_QUERY,
  SEARCH_RESULTS_SUCCESS,
  SEARCH_RESULTS_FAILURE,
  SEARCH_RESULTS_PER_PAGE,
  SEARCH_RESULTS_PAGE_COUNT,
  SEARCH_RESULTS_CURRENT_PAGE,
} from '../actionTypes';
import { getSearchResultsByHashtag, getSearchResultsByWords } from '../../requests/requests';

export const setSearchQuery = (searchQuery) => ({
  type: SEARCH_QUERY,
  searchQuery,
});

export const searchResultsRequestSucceeded = (searchResultsList) => ({
  type: SEARCH_RESULTS_SUCCESS,
  searchResultsList,
});

export const searchResultsRequestFailed = (notification) => ({
  type: SEARCH_RESULTS_FAILURE,
  notification,
});

export const setSearchResultsPerPage = (perPage) => ({
  type: SEARCH_RESULTS_PER_PAGE,
  perPage,
});

export const setSearchResultsPageCount = (pageCount) => ({
  type: SEARCH_RESULTS_PAGE_COUNT,
  pageCount,
});

export const setSearchResultsCurrentPage = (currentPage) => ({
  type: SEARCH_RESULTS_CURRENT_PAGE,
  currentPage,
});

export const searchResultsAPI = () => (dispatch, getState) => {
  const {
    searchQuery,
    searchResultsList,
    perPage,
    pageCount,
    currentPage,
    isReady,
  } = { ...getState().searchResults };

  let searchRequest;

  if (searchQuery[0] === '#') {
    searchRequest = getSearchResultsByHashtag;
  } else {
    searchRequest = getSearchResultsByWords;
  }

  if (!perPage && !pageCount && !currentPage && currentPage !== 1 && !isReady) {
    if (!searchResultsList[currentPage]) {
      searchRequest(searchQuery, perPage, currentPage)
        .then((response) => {
          const newState = {
            ...searchResultsList,
            [`${currentPage}`]: response.data.search_results,
          };

          dispatch(searchResultsRequestSucceeded(newState));
        })
        .catch((error) => dispatch(searchResultsRequestFailed(error.message)))
        .finally(() => {});
    }
  }
};
