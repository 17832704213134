/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import './SliderInfo.scss';

export default function SliderInfo({
  id, title, description, type, image,
}) {
  useEffect(() => {}, []);

  return (
    <tr>
      <td>{id}</td>
      <td>{title}</td>
      <td>{description}</td>
      <td>{type}</td>
      <td>{image}</td>
    </tr>
  );
}
