/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import SliderInfo from '../SliderInfo/SliderInfo';
import { getSliders } from '../../../../requests/requests';
import './SliderList.scss';

export default function SliderList() {
  const [sliderData, setSliderData] = useState();

  useEffect(() => {
    getSliders()
      .then((response) => {
        console.log(response.data);
        setSliderData(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  const onAddHandler = () => {
    const newSlider = {
      title: '', description: '', type: '', image: '',
    };
    const newSliderData = [newSlider, ...sliderData];
    setSliderData(newSliderData);
  };
  return (
    <section className="SliderList">
      <button type="button" className="admin_button" onClick={onAddHandler}>Add</button>
      <table className="advertising-table">
        <thead>
          <tr>
            <th>
              ID:
            </th>
            <th>
              Title:
            </th>
            <th>
              Description:
            </th>
            <th>
              type:
            </th>
            <th>
              image:
            </th>
          </tr>
        </thead>
        { sliderData && sliderData.map((slider) => (
          <SliderInfo
            id={slider.id}
            title={slider.tile}
            description={slider.description}
            type={slider.type}
            image={slider.image}
          />
        ))}
      </table>
    </section>
  );
}
