import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import ErrorPage from './Components/ErrorPage/ErrorPage';
import Landing from './Components/LandingPage/Landing/Landing';
import SearchResultsPage from './Components/SearchResultsPage/SearchResultsPage';
import CustomsAndBrokerageServices from './Components/ServicePages/CustomsAndBrokerageServices/CustomsAndBrokerageServices';
import Expertise from './Components/ServicePages/Expertise/Expertise';
import InternationalCooperation from './Components/ServicePages/InternationalCooperation/InternationalCooperation';
import ForceMajeureCertification from './Components/ServicePages/ForceMajeureCertification/ForceMajeureCertification';
import CertificatesBarcodesATAcarnets from './Components/ServicePages/CertificatesBarcodesATAcarnets/CertificatesBarcodesATAcarnets';
import ArbitrationCourt from './Components/ServicePages/ArbitrationCourt/ArbitrationCourt';
import ConferenceServices from './Components/ServicePages/ConferenceServices/ConferenceServices';
import ActivitiesPage from './Components/ActivitiesPage/ActivitiesPage';
import EventPage from './Components/EventPage/EventPage';
import CommunityPage from './Components/CommunityPage/CommunityPage';
import AboutUsPage from './Components/AboutUsPage/AboutUsPage';
import NewsEventsPage from './Components/NewsEventsPage/NewsEventsPage';
import ArticlePage from './Components/ArticlePage/ArticlePage';
import CompanyProfile from './Components/PersonalAccount/CompanyProfile/CompanyProfile';
import CCIDocuments from './Components/PersonalAccount/CCIDocuments/CCIDocuments';
import ClientDocuments from './Components/PersonalAccount/ClientDocuments/ClientDocuments';
import ServiceOrder from './Components/PersonalAccount/ServiceOrder/ServiceOrder';
import Proposal from './Components/PersonalAccount/Proposal/Proposal';
import Initiative from './Components/PersonalAccount/Initiative/Initiative';
import Adminpanel from './routes/adminpanel';
import myStore from './store/myStore';
import PageLoader from './Components/ReusableElements/PageLoader/PageLoader';
import './i18next/i18next';
import './index.scss';

const myRouter = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Landing />,
      },
      {
        path: '/en/',
        element: <Landing />,
      },
      {
        path: 'search_results/',
        element: <SearchResultsPage />,
      },
      {
        path: 'search_results/en/',
        element: <SearchResultsPage />,
      },
      {
        path: 'search_results/:articleId/',
        element: <ArticlePage />,
      },
      {
        path: 'search_results/:articleId/en/',
        element: <ArticlePage />,
      },
      {
        path: 'news_events/',
        element: <NewsEventsPage />,
      },
      {
        path: 'news_events/en/',
        element: <NewsEventsPage />,
      },
      {
        path: 'news_events/:articleId/',
        element: <ArticlePage />,
      },
      {
        path: 'news_events/:articleId/en/',
        element: <ArticlePage />,
      },
      {
        path: 'services/customs_and_brokerage_services/',
        element: <CustomsAndBrokerageServices />,
      },
      {
        path: 'services/customs_and_brokerage_services/en/',
        element: <CustomsAndBrokerageServices />,
      },
      {
        path: 'services/expertise/',
        element: <Expertise />,
      },
      {
        path: 'services/expertise/en/',
        element: <Expertise />,
      },
      {
        path: 'services/international_cooperation/',
        element: <InternationalCooperation />,
      },
      {
        path: 'services/international_cooperation/en/',
        element: <InternationalCooperation />,
      },
      {
        path: 'services/force_majeure_certification/',
        element: <ForceMajeureCertification />,
      },
      {
        path: 'services/force_majeure_certification/en/',
        element: <ForceMajeureCertification />,
      },
      {
        path: 'services/certificates_barcodes_ATA_carnets/',
        element: <CertificatesBarcodesATAcarnets />,
      },
      {
        path: 'services/certificates_barcodes_ATA_carnets/en/',
        element: <CertificatesBarcodesATAcarnets />,
      },
      {
        path: 'services/arbitration_court/',
        element: <ArbitrationCourt />,
      },
      {
        path: 'services/arbitration_court/en/',
        element: <ArbitrationCourt />,
      },
      {
        path: 'services/conference_services/',
        element: <ConferenceServices />,
      },
      {
        path: 'services/conference_services/en/',
        element: <ConferenceServices />,
      },
      {
        path: 'activities/',
        element: <ActivitiesPage />,
      },
      {
        path: 'activities/en/',
        element: <ActivitiesPage />,
      },
      {
        path: 'activities/:eventId/',
        element: <EventPage />,
      },
      {
        path: 'activities/:eventId/en/',
        element: <EventPage />,
      },
      {
        path: 'about_us/',
        element: <AboutUsPage />,
      },
      {
        path: 'about_us/en/',
        element: <AboutUsPage />,
      },
      {
        path: 'community/',
        element: <CommunityPage />,
      },
      {
        path: 'community/en/',
        element: <CommunityPage />,
      },
      {
        path: 'personal_account/company_profile/',
        element: <CompanyProfile />,
      },
      {
        path: 'personal_account/company_profile/en/',
        element: <CompanyProfile />,
      },
      {
        path: 'personal_account/cci_documents/',
        element: <CCIDocuments />,
      },
      {
        path: 'personal_account/cci_documents/en/',
        element: <CCIDocuments />,
      },
      {
        path: 'personal_account/client_documents/',
        element: <ClientDocuments />,
      },
      {
        path: 'personal_account/client_documents/en/',
        element: <ClientDocuments />,
      },
      {
        path: 'personal_account/service_order/',
        element: <ServiceOrder />,
      },
      {
        path: 'personal_account/service_order/en/',
        element: <ServiceOrder />,
      },
      {
        path: 'personal_account/proposal/',
        element: <Proposal />,
      },
      {
        path: 'personal_account/proposal/en/',
        element: <Proposal />,
      },
      {
        path: 'personal_account/initiative/',
        element: <Initiative />,
      },
      {
        path: 'personal_account/initiative/en/',
        element: <Initiative />,
      },
    ],
  },
  {
    path: '/adminpanel/',
    element: <Adminpanel />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={myStore}>
    <Suspense fallback={<PageLoader />}>
      <RouterProvider router={myRouter} />
    </Suspense>
  </Provider>,
);
