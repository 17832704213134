/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { eventsAPI } from '../../store/actions/eventsActions';
import ActivityList from '../ActivityList/ActivityList';
import './ActivitiesPage.scss';

export default function ActivitiesPage() {
  const { t, i18n } = useTranslation(['activities_page', 'notifications']);
  const [minimumPageRequiredHeight, setMinimumPageRequiredHeight] = useState();
  const dispatch = useDispatch();
  const {
    eventList,
    perPage,
    pageCount,
    currentPage,
    isReady,
    notification,
  } = useSelector((state) => state.events);

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant',
    });

    dispatch(eventsAPI());
  }, [currentPage]);

  useEffect(() => {
    const header = document.querySelector('.Header.fixed');
    const activitiesPage = document.querySelector('.ActivitiesPage');
    const footer = document.querySelector('.Footer');
    const minimumRequiredHeight = document.documentElement.clientHeight - (header.offsetHeight + footer.offsetHeight);

    activitiesPage.style.minHeight = `${minimumRequiredHeight}px`;
    setMinimumPageRequiredHeight(minimumRequiredHeight);
  }, []);

  return (
    <section className="ActivitiesPage">
      <Helmet>
        <title>
          {t('heading')}
        </title>
        <meta
          name="description"
          content={t('heading')}
        />
        <meta property="og:locale" content="uk_UA" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={t('heading')} />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
      </Helmet>
      <div className="wrapper">
        <div className="activities-page-content">
          <a
            className="previous-page-link"
            href={
              i18n.languages[0] === 'en'
                ? '/en/'
                : '/'
            }
          >
            {t('previous_page_title')}
          </a>
          <h1 className="heading-one">
            {t('heading')}
          </h1>
          <p className="description">
            {t('description')}
          </p>
          {
            perPage && pageCount !== null && currentPage && isReady
              ? (
                <ActivityList
                  eventList={eventList}
                  pageCount={pageCount}
                  currentPage={currentPage}
                  minimumPageRequiredHeight={minimumPageRequiredHeight}
                />
              )
              : (
                <p className="notification">
                  {t(`notifications:${notification}`, notification)}
                </p>
              )
          }
        </div>
      </div>
    </section>
  );
}
