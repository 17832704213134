/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Navbar from '../Components/Adminpanel/Navbar/Navbar';
import Menu from '../Components/Adminpanel/Menu/Menu';
import UsersList from '../Components/Adminpanel/UsersList/UsersList';
// import FileInput from '../Components/Adminpanel/FileInput/FileInput';
import defaultMenuItems from '../constants/adminMenu';
import './adminpanel.scss';
import ArticleList from '../Components/Adminpanel/ArticleList/ArticleList';
// import Article from '../Components/Adminpanel/Article/Article';
import ManagerList from '../Components/Adminpanel/Managers/ManagerList/ManagerList';
import EventList from '../Components/Adminpanel/Events/EventList/EventList';
import CompanyList from '../Components/Adminpanel/Companies/CompanysList/CompanyList';
import SliderList from '../Components/Adminpanel/Slider/SliderList/SliderList';
import AdvertisingList from '../Components/Adminpanel/Adds/AddvertisingList/AdvertisingList';

export default function Adminpanel() {
  const [menuItems, setMenuItems] = useState(defaultMenuItems);

  const getOpenItem = () => menuItems.find((item) => item.open);

  const onExit = () => {
    console.log('on exit clicked');
  };

  useEffect(() => {
    const {
      protocol,
      host,
      pathname,
      search,
      hash,
      href,
    } = window.location;

    let finalProtocol;
    let finalHost;
    let finalPathname;

    if (!host.endsWith('localhost:3000') && protocol === 'http:') {
      finalProtocol = 'https:';
    } else {
      finalProtocol = protocol;
    }

    if (host.startsWith('www.')) {
      finalHost = host.slice(4);
    } else {
      finalHost = host;
    }

    if (pathname.endsWith('/')) {
      finalPathname = pathname;
    } else {
      finalPathname = `${pathname}/`;
    }

    const finalHref = `${finalProtocol}//${finalHost}${finalPathname}${search}${hash}`;

    if (finalHref !== href) {
      window.location.assign(finalHref);
    }
  }, []);

  return (
    <section className="Adminpanel">
      <Navbar role="Суперадмін" onExit={onExit} />
      <div className="AdminpanelBlock">
        <Menu menuItems={menuItems} setMenuItems={setMenuItems} />
        <div className="AdminpanelContent">
          {(() => {
            const openItem = getOpenItem();
            if (!openItem) return null;
            switch (openItem.name) {
              case 'Користувачі': return <UsersList />;
              case 'Реклама': return <AdvertisingList />;
              case 'Слайдер': return <SliderList />;
              case 'Керівництво': return <ManagerList />;
              case 'Статті': return <ArticleList />;
              case 'Події': return <EventList />;
              case 'Послуги': return <div>Послуги</div>;
              case 'Компанії': return <CompanyList />;
              case 'Сповіщення': return <div>Сповіщення</div>;
              default: return null;
            }
          })()}
        </div>
      </div>
    </section>
  );
}
