/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
// import Button from '../ReusableElements/Buttons/Button/Button';
import eventAPI from '../../store/actions/eventActions';
import calendarWhite from '../../assets/icons/calendar_white.svg';
import calendarGreen from '../../assets/icons/info/calendar_green.svg';
import person from '../../assets/icons/info/person.svg';
import phoneGreen from '../../assets/icons/info/phone_green.svg';
import email from '../../assets/icons/info/email.svg';
import ContactForm from '../LandingPage/ContactForm/ContactForm';
import 'moment/locale/uk';
import './EventPage.scss';

export default function EventPage() {
  const { t, i18n } = useTranslation(['event_page', 'buttons', 'notifications']);
  const dispatch = useDispatch();
  const {
    data,
    isReady,
    notification,
  } = useSelector((state) => state.event);
  const { eventId } = useParams();
  const navigate = useNavigate();

  const availableWindowWidth = document.documentElement.clientWidth;

  const eventDetails = (
    <div className="event-details">
      <h3 className="heading-three">
        {t('event_details')}
      </h3>
      {/* <Button
        priority="primary"
        link="https://docs.google.com/forms/"
        target="_blank"
      >
        {t('buttons:registration')}
      </Button> */}
    </div>
  );

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant',
    });

    dispatch(eventAPI(eventId));
  }, [eventId]);

  useEffect(() => {
    const header = document.querySelector('.Header.fixed');
    const eventPage = document.querySelector('.EventPage');
    const footer = document.querySelector('.Footer');
    const minimumRequiredHeight = document.documentElement.clientHeight - (header.offsetHeight + footer.offsetHeight);

    eventPage.style.minHeight = `${minimumRequiredHeight}px`;
  }, []);

  return (
    <section className="EventPage">
      <div className="wrapper">
        <div className="event-page-content">
          <button
            className="previous-page-link"
            type="button"
            onClick={() => navigate(
              i18n.languages[0] === 'en'
                ? '/activities/en/'
                : '/activities/',
            )}
          >
            {t('previous_page_title')}
          </button>
          {
            isReady
              ? (
                <>
                  <h1 className="heading-one">
                    {data.title}
                  </h1>
                  <div className="event-cover">
                    <div className="image-dimming" />
                    <img
                      className="event-image"
                      src={data.cover?.url}
                      alt={data.title}
                    />
                    <div className="event-title">
                      <img
                        className="cover-icon"
                        src={calendarWhite}
                        alt="calendarWhite"
                      />
                      <h3 className="heading-three heading-three_cover">
                        {data.title}
                      </h3>
                    </div>
                    <Moment
                      className="event-date"
                      format="LLL"
                      locale={
                        i18n.languages[0] === 'en'
                          ? 'en'
                          : 'uk'
                      }
                    >
                      {data.event_date}
                    </Moment>
                  </div>
                  <div className="event-content">
                    <div className="event-content-top-row">
                      {
                        availableWindowWidth <= 601 && eventDetails
                      }
                      <div className="event-basic-info">
                        <div className="basic-info-item">
                          <img
                            className="info-icon"
                            src={calendarGreen}
                            alt="calendarGreen"
                          />
                          <p className="paragraph">
                            {`${t('basic_info.event_date')}: `}
                            <Moment format="YYYY-MM-DD">
                              {data.event_date}
                            </Moment>
                          </p>
                        </div>
                        {
                          data.coordinator && (
                            <div className="basic-info-item">
                              <img
                                className="info-icon"
                                src={person}
                                alt="person"
                              />
                              <p className="paragraph">
                                {`${t('basic_info.coordinator')}: ${data.coordinator}`}
                              </p>
                            </div>
                          )
                        }
                        {
                          data.phone && (
                            <div className="basic-info-item">
                              <img
                                className="info-icon"
                                src={phoneGreen}
                                alt="phoneGreen"
                              />
                              <p className="paragraph">
                                {`${t('basic_info.phone')}: ${data.phone}`}
                              </p>
                            </div>
                          )
                        }
                        {
                          data.email && (
                            <div className="basic-info-item">
                              <img
                                className="info-icon"
                                src={email}
                                alt="email"
                              />
                              <p className="paragraph">
                                {`${t('basic_info.email')}: ${data.email}`}
                              </p>
                            </div>
                          )
                        }
                      </div>
                    </div>
                    <div className="event-content-bottom-row">
                      {
                        availableWindowWidth > 601 && eventDetails
                      }
                      <p
                        className="event-description"
                        dangerouslySetInnerHTML={{ __html: data.description }}
                      />
                    </div>
                  </div>
                  <h2 className="heading-two heading-two_form">
                    {t('contact_form_heading')}
                  </h2>
                  <p className="form-description">
                    {t('contact_form_description')}
                  </p>
                  <ContactForm />
                </>
              )
              : (
                <p className="notification">
                  {t(`notifications:${notification}`, notification)}
                </p>
              )
          }
        </div>
      </div>
    </section>
  );
}
