import { EVENT_SUCCESS, EVENT_FAILURE } from '../actionTypes';
import { getEventItem } from '../../requests/requests';

const eventRequestSucceeded = (data) => ({
  type: EVENT_SUCCESS,
  data,
});

const eventRequestFailed = (notification) => ({
  type: EVENT_FAILURE,
  notification,
});

const eventAPI = (eventId) => async (dispatch) => {
  try {
    const response = await getEventItem(eventId);

    dispatch(eventRequestSucceeded(response.data));
  } catch (error) {
    dispatch(eventRequestFailed(error.message));
  } finally {
    console.log('finally');
  }
};

export default eventAPI;
