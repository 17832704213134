/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { getManagers } from '../../../../requests/requests';
import ManagerInfo from '../ManagerInfo/ManagerInfo';
import './ManagerList.scss';

export default function ManagerList() {
  const [managerData, setManagerData] = useState();

  useEffect(() => {
    getManagers()
      .then((response) => {
        console.log(response.data);
        setManagerData(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  return (
    <section className="ManagerList">
      <table className="manager-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Ім&apos;я</th>
            <th>Прізвище</th>
            <th>Позиція</th>
            <th>Опис</th>
            <th>Email</th>
            <th>Телефон</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {managerData && managerData.map((manager) => (
            <ManagerInfo
              id={manager.id}
              name={manager.name}
              surname={manager.surname}
              position={manager.position}
              description={manager.description}
              email={manager.email}
              phone={manager.phone}
            />
          ))}
        </tbody>
      </table>
    </section>
  );
}
