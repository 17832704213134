/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import ArticleInfo from '../ArticleInfo/ArticleInfo';
import { getArticles } from '../../../requests/requests';
import './ArticleList.scss';
// import Article from '../Article/Article';

export default function ArticleList() {
  const [articleData, setArticleData] = useState();

  useEffect(() => {
    getArticles()
      .then((response) => {
        console.log(response.data);
        setArticleData(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);
  const onAddHandler = () => {
    const newArticle = { title: '', description: '', url: '' };
    const newArticleData = [newArticle, ...articleData];
    setArticleData(newArticleData);
  };
  return (
    <section className="ArticleList">
      <button type="button" className="admin_button" onClick={onAddHandler}>Add</button>
      <table className="articles-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Назва</th>
            <th>Опис</th>
            <th>Статус</th>
            <th>Автор</th>
          </tr>
        </thead>
        <tbody>
          {articleData && articleData.map((article) => (
            <ArticleInfo
              key={article.id}
              id={article.id}
              title={article.title}
              description={article.description}
              author={article.author}
              status={article.aasm_state}
            />
          ))}
        </tbody>
      </table>
      {/* <Article /> */}
    </section>
  );
}
