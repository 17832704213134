/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { hideRegistration, showInfoMessage } from '../../../store/actions/modalWindowsActions';
import { finishRegistration } from '../../../requests/requests';
import closeWhite from '../../../assets/icons/actions/close_white.svg';
import OrdinaryInput from '../../ReusableElements/FormElemets/OrdinaryInput/OrdinaryInput';
import PhoneInputElement from '../../ReusableElements/FormElemets/PhoneInputElement/PhoneInputElement';
import Submit from '../../ReusableElements/Buttons/Submit/Submit';
import './Registration.scss';

export default function Registration() {
  const { t, i18n } = useTranslation(['form_error_messages', 'form_fields', 'buttons']);
  const [minPhoneLengthLimit, setMinPhoneLengthLimit] = useState();
  const [requiredPhoneLength, setRequiredPhoneLength] = useState();
  const [currentDialCode, setCurrentDialCode] = useState('380');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('+380');
  const dispatch = useDispatch();
  const {
    resetPasswordToken,
    email,
  } = useSelector((state) => state.modalWindows.registration);
  const navigate = useNavigate();

  const closeModalWindow = () => {
    dispatch(hideRegistration());
    navigate(
      i18n.languages[0] === 'en'
        ? '/en/'
        : '/',
    );
  };

  const registrationSchema = Yup.object({
    company_name: Yup
      .string()
      .required(t('registration.company_name.required'))
      .matches(/^["“”„“«».,:()'/!?№+=*@a-zа-щєіїьюя\d\s–-]+$/i, t('registration.company_name.matches')),
    edrpou_code: Yup
      .string()
      .required(t('registration.edrpou_code.required'))
      .matches(/^[\d]+$/, t('registration.edrpou_code.matches'))
      .length(8, t('registration.edrpou_code.length')),
    kind_of_activity: Yup
      .string()
      .required(t('registration.kind_of_activity.required'))
      .matches(/^[.,:()'/a-zа-щєіїьюя\d\s–-]+$/i, t('registration.kind_of_activity.matches')),
    legal_address: Yup
      .string()
      .required(t('registration.legal_address.required'))
      .matches(/^[.,:()'/№a-zа-щєіїьюя\d\s–-]+$/i, t('registration.legal_address.matches')),
    physical_address: Yup
      .string()
      .required(t('registration.physical_address.required'))
      .matches(/^[.,:()'/№a-zа-щєіїьюя\d\s–-]+$/i, t('registration.physical_address.matches')),
    phone: Yup
      .string()
      .min(minPhoneLengthLimit + 2, t('registration.phone.min'))
      .length(requiredPhoneLength + 1, t('registration.phone.length')),
    email: Yup
      .string()
      .required(t('registration.email.required'))
      .email(t('registration.email.email')),
    website: Yup
      .string()
      .required(t('registration.website.required'))
      .url(t('registration.website.url')),
    new_password: Yup
      .string()
      .required(t('registration.new_password.required'))
      .min(8, t('registration.new_password.min'))
      .max(25, t('registration.new_password.max')),
    new_password_confirmation: Yup
      .string()
      .required(t('registration.new_password_confirmation.required'))
      .oneOf([Yup.ref('new_password')], t('registration.new_password_confirmation.oneOf')),
  });

  const submitRegistration = (values, actions) => {
    const {
      company_name,
      edrpou_code,
      kind_of_activity,
      legal_address,
      physical_address,
      phone,
      website,
      new_password,
      new_password_confirmation,
    } = values;
    const formData = {
      reset_password_token: resetPasswordToken,
      password: new_password,
      password_confirmation: new_password_confirmation,
      company_data: {
        name: company_name.trim(),
        edrpou_code,
        kind_of_activity: kind_of_activity.trim(),
        legal_address: legal_address.trim(),
        physical_address: physical_address.trim(),
        phone,
        email,
        website: website.toLowerCase(),
      },
    };

    finishRegistration(formData)
      .then((response) => {
        if (response.status === 200) {
          closeModalWindow();
          dispatch(showInfoMessage('successful_registration'));
        }

        console.log(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const setPhoneParameters = (minLengthLimit, requiredLength) => {
    setMinPhoneLengthLimit(minLengthLimit);
    setRequiredPhoneLength(requiredLength);
  };

  useEffect(() => {
    const availableWindowWidth = document.documentElement.clientWidth;
    const container = document.querySelector('.registration .container');
    const modalWindow = document.querySelector('.registration .modal-window');

    if (availableWindowWidth > 834 && container.offsetHeight < modalWindow.offsetHeight + 12) {
      container.style.alignItems = 'flex-start';
      modalWindow.style.marginTop = '12px';
    } else {
      container.style.alignItems = '';
      modalWindow.style.marginTop = '';
    }
  }, []);

  return (
    <div className="registration">
      <div className="container">
        <div className="modal-window">
          <h1 className="heading-one">
            {t('form_fields:registration.heading')}
          </h1>
          <button
            className="close"
            type="button"
            onClick={closeModalWindow}
          >
            <img
              src={closeWhite}
              alt="closeWhite"
            />
          </button>
          <Formik
            initialValues={{
              company_name: '',
              edrpou_code: '',
              kind_of_activity: '',
              legal_address: '',
              physical_address: '',
              phone: '+380',
              email,
              website: '',
              new_password: '',
              new_password_confirmation: '',
            }}
            validationSchema={registrationSchema}
            onSubmit={submitRegistration}
          >
            {(formik) => (
              <Form className="registration-form">
                <div className="registration-form-fields">
                  <OrdinaryInput
                    label={t('form_fields:registration.fields.company_name.label')}
                    placeholder={t('form_fields:registration.fields.company_name.placeholder')}
                    name="company_name"
                    type="text"
                  />
                  <OrdinaryInput
                    label={t('form_fields:registration.fields.edrpou_code.label')}
                    placeholder={t('form_fields:registration.fields.edrpou_code.placeholder')}
                    name="edrpou_code"
                    type="text"
                    maxLength={8}
                  />
                  <OrdinaryInput
                    label={t('form_fields:registration.fields.kind_of_activity.label')}
                    placeholder={t('form_fields:registration.fields.kind_of_activity.placeholder')}
                    name="kind_of_activity"
                    type="text"
                  />
                  <OrdinaryInput
                    label={t('form_fields:registration.fields.legal_address.label')}
                    placeholder={t('form_fields:registration.fields.legal_address.placeholder')}
                    name="legal_address"
                    type="text"
                  />
                  <OrdinaryInput
                    label={t('form_fields:registration.fields.physical_address.label')}
                    placeholder={t('form_fields:registration.fields.physical_address.placeholder')}
                    name="physical_address"
                    type="text"
                  />
                  <PhoneInputElement
                    label={t('form_fields:registration.fields.phone.label')}
                    placeholder={t('form_fields:registration.fields.phone.placeholder')}
                    name="phone"
                    type="tel"
                    setPhoneParameters={setPhoneParameters}
                    currentDialCode={currentDialCode}
                    setCurrentDialCode={setCurrentDialCode}
                    formattedPhoneNumber={formattedPhoneNumber}
                    setFormattedPhoneNumber={setFormattedPhoneNumber}
                  />
                  <OrdinaryInput
                    label={t('form_fields:registration.fields.email.label')}
                    placeholder={t('form_fields:registration.fields.email.placeholder')}
                    name="email"
                    type="email"
                    isDisabled
                  />
                  <OrdinaryInput
                    label={t('form_fields:registration.fields.website.label')}
                    placeholder={t('form_fields:registration.fields.website.placeholder')}
                    name="website"
                    type="url"
                  />
                  <OrdinaryInput
                    label={t('form_fields:registration.fields.new_password.label')}
                    placeholder={t('form_fields:registration.fields.new_password.placeholder')}
                    name="new_password"
                    width="49%"
                  />
                  <OrdinaryInput
                    label={t('form_fields:registration.fields.new_password_confirmation.label')}
                    placeholder={t('form_fields:registration.fields.new_password_confirmation.placeholder')}
                    name="new_password_confirmation"
                    width="49%"
                  />
                </div>
                <Submit
                  priority="primary"
                  isDisabled={formik.isSubmitting}
                >
                  {t('buttons:sign_up')}
                </Submit>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
