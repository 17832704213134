/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import './AdvertisingItem.scss';
import AdvertisingInfo from '../AdvertisingInfo/AdvertisingInfo';
import Advertising from '../Advertising/Advertising';

export default function AdvertisingItem({ data }) {
  useEffect(() => {}, []);
  const [isUpdatable, setIsUpdatable] = useState(false);
  const isUpdatableHandler = () => setIsUpdatable((state) => !state);

  return (

    isUpdatable
      ? (
        <Advertising
          data={data}
          onChangeView={isUpdatableHandler}
        />
      )
      : (
        <AdvertisingInfo
          data={data}
          onChangeView={isUpdatableHandler}
        />
      )
  );
}
