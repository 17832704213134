import { MANAGERS_SUCCESS, MANAGERS_FAILURE } from '../actionTypes';
import { getManagers } from '../../requests/requests';

const managersRequestSucceeded = (managerData) => ({
  type: MANAGERS_SUCCESS,
  managerData,
});

const managersRequestFailed = (notification) => ({
  type: MANAGERS_FAILURE,
  notification,
});

const managersAPI = () => (dispatch) => {
  getManagers()
    .then((response) => {
      console.log(response.data);
      console.log(response.status);
      console.log(response.statusText);
      console.log(response.headers);
      console.log(response.config);
      console.log(response.request);

      dispatch(managersRequestSucceeded(response.data));
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.statusText);
        console.log(error.response.headers);
        console.log(error.response.config);
        console.log(error.response.request);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log(error.config);
      }

      console.log(error.message);

      dispatch(managersRequestFailed(error.message));
    })
    .finally(() => {});
};

export default managersAPI;
