/* eslint-disable global-require */
import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import { thunk } from 'redux-thunk';
import rootReducer from './reducers/rootReducer';

let composedEnhancer;

if (process.env.NODE_ENV === 'development') {
  const { composeWithDevTools } = require('redux-devtools-extension');

  composedEnhancer = composeWithDevTools(applyMiddleware(thunk));
} else {
  composedEnhancer = applyMiddleware(thunk);
}

const myStore = createStore(rootReducer, composedEnhancer);

export default myStore;
