import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { sendProposalForm } from '../../requests/requests';
import { showInfoMessage } from '../../store/actions/modalWindowsActions';
import PhoneInputElement from '../ReusableElements/FormElemets/PhoneInputElement/PhoneInputElement';
import OrdinaryInput from '../ReusableElements/FormElemets/OrdinaryInput/OrdinaryInput';
import Textarea from '../ReusableElements/FormElemets/Textarea/Textarea';
import Submit from '../ReusableElements/Buttons/Submit/Submit';
import InfoMessage from '../ModalWindows/InfoMessage/InfoMessage';
import './ProposalForm.scss';

export default function ProposalForm() {
  const { t } = useTranslation(['form_error_messages', 'form_fields', 'buttons', 'info_messages']);
  const [minPhoneLengthLimit, setMinPhoneLengthLimit] = useState();
  const [requiredPhoneLength, setRequiredPhoneLength] = useState();
  const [currentDialCode, setCurrentDialCode] = useState('380');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('+380');
  const dispatch = useDispatch();
  const {
    isInfoMessageVisible,
    contentKey,
  } = useSelector((state) => state.modalWindows.infoMessage);

  const proposalSchema = Yup.object({
    phone: Yup
      .string()
      .min(minPhoneLengthLimit + 2, t('proposal_form.phone.min'))
      .length(requiredPhoneLength + 1, t('proposal_form.phone.length')),
    email: Yup
      .string()
      .required(t('proposal_form.email.required'))
      .email(t('proposal_form.email.email')),
    proposal_content: Yup
      .string()
      .trim()
      .required(t('proposal_form.proposal_content.required')),
  });

  const sendProposal = (values, actions) => {
    const formData = { ...values };

    formData.email = formData.email.toLowerCase();
    formData.proposal_content = formData.proposal_content.trim();

    sendProposalForm(formData)
      .then((response) => {
        if (response.status === 200) {
          actions.resetForm({
            touched: {},
            submitCount: 0,
          });

          setCurrentDialCode('380');
          setFormattedPhoneNumber('+380');
          dispatch(showInfoMessage('proposal_form'));
        }

        console.log(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const setPhoneParameters = (minLengthLimit, requiredLength) => {
    setMinPhoneLengthLimit(minLengthLimit);
    setRequiredPhoneLength(requiredLength);
  };

  return (
    <>
      <Formik
        initialValues={{
          phone: '+380',
          email: '',
          proposal_content: '',
        }}
        validationSchema={proposalSchema}
        onSubmit={sendProposal}
      >
        {(formik) => (
          <Form className="proposal-form">
            <div className="proposal-form-fields">
              <PhoneInputElement
                label={t('form_fields:proposal_form.phone.label')}
                placeholder={t('form_fields:proposal_form.phone.placeholder')}
                name="phone"
                type="tel"
                setPhoneParameters={setPhoneParameters}
                currentDialCode={currentDialCode}
                setCurrentDialCode={setCurrentDialCode}
                formattedPhoneNumber={formattedPhoneNumber}
                setFormattedPhoneNumber={setFormattedPhoneNumber}
              />
              <OrdinaryInput
                label={t('form_fields:proposal_form.email.label')}
                placeholder={t('form_fields:proposal_form.email.placeholder')}
                name="email"
                type="email"
              />
              <Textarea
                label={t('form_fields:proposal_form.proposal_content.label')}
                placeholder={t('form_fields:proposal_form.proposal_content.placeholder')}
                name="proposal_content"
                rows={2}
                maxRows={7}
              />
            </div>
            <Submit
              priority="primary"
              isDisabled={formik.isSubmitting}
            >
              {t('buttons:send')}
            </Submit>
          </Form>
        )}
      </Formik>
      {
        isInfoMessageVisible && contentKey === 'proposal_form' && (
          <InfoMessage>
            {t('info_messages:proposal_form')}
          </InfoMessage>
        )
      }
    </>
  );
}
