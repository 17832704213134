/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showRegistration } from '../../../store/actions/modalWindowsActions';
import Main from '../Main/Main';
import Services from '../Services/Services';
import Activities from '../Activities/Activities';
import Community from '../Community/Community';
import Management from '../Management/Management';
import NewsEvents from '../NewsEvents/NewsEvents';
import OurPartners from '../OurPartners/OurPartners';
import Forms from '../Forms/Forms';
import Registration from '../../ModalWindows/Registration/Registration';
import InfoMessage from '../../ModalWindows/InfoMessage/InfoMessage';
import './Landing.scss';

export default function Landing() {
  const { t } = useTranslation('info_messages');
  const {
    search,
    hash,
  } = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const eventsNotification = useSelector((state) => state.events.notification);
  const managersNotification = useSelector((state) => state.managers.notification);
  const articlesNotification = useSelector((state) => state.articles.notification);
  const vipCompaniesNotification = useSelector((state) => state.vipCompanies.notification);
  const { isRegistrationVisible } = useSelector((state) => state.modalWindows.registration);
  const {
    isInfoMessageVisible,
    contentKey,
  } = useSelector((state) => state.modalWindows.infoMessage);

  useEffect(() => {
    if (search) {
      const resetPasswordToken = searchParams.get('reset_password_token');
      const email = searchParams.get('email');

      if (resetPasswordToken && email) {
        dispatch(showRegistration(resetPasswordToken, email));
      }
    }

    if (hash) {
      let css;

      switch (hash) {
        case '#main':
          css = '.Main';
          break;
        case '#services':
          css = '.Services';
          break;
        case '#activities':
          css = '.Activities';
          break;
        case '#about-us':
          css = '.Management';
          break;
        case '#news-events':
          css = '.NewsEvents';
          break;
        case '#forms':
          css = '.Forms';
          break;
        default:
          break;
      }

      const section = document.querySelector(css);

      window.scrollTo({
        left: 0,
        top: section.offsetTop,
        behavior: 'instant',
      });
    }
  }, [eventsNotification, managersNotification, articlesNotification, vipCompaniesNotification]);
  const ldJson = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Івано-Франківська торгово-промислова палата',
    url: 'https://cci.if.ua/',
    logo: 'https://cci.if.ua/static/media/logo_small.088e05e0446873d0c8dfb27546b82194.svg',
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+380342523347',
      contactType: 'customer service',
      areaServed: 'UA',
      availableLanguage: 'Ukrainian',
    },
    sameAs: [
      'https://www.facebook.com/cci.if.ua/',
      'https://www.instagram.com/if.cci.ua/',
    ],
  };
  return (
    <>
      <Helmet>
        <title>Послуги Івано-Франківської торгово-промислової палати (ІФ ТПП)</title>
        <meta
          name="description"
          content="найдіть контактну інформацію та дізнайтеся, як швидко зв'язатися з Івано-Франківською торгово-промисловою палатою для отримання допомоги та консультацій.найдіть контактну інформацію та дізнайтеся, як швидко зв'язатися з Івано-Франківською торгово-промисловою палатою для отримання допомоги та консультацій."
        />
        <meta property="og:locale" content="uk_UA" />
        <meta property="og:url" content="https://cci.if.ua" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="IFCCI | Welcome" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <script type="application/ld+json">
          {JSON.stringify(ldJson)}
        </script>
      </Helmet>
      <Main />
      <NewsEvents />
      <Services />
      <Activities />
      <Management />
      <Community />
      <OurPartners />
      <Forms />
      {
        isRegistrationVisible && <Registration />
      }
      {
        isInfoMessageVisible && contentKey === 'successful_registration' && (
        <InfoMessage>
          {t('successful_registration')}
        </InfoMessage>
        )
      }
    </>
  );
}
