import {
  EVENTS_SUCCESS,
  EVENTS_FAILURE,
  EVENTS_PER_PAGE,
  EVENTS_PAGE_COUNT,
  EVENTS_CURRENT_PAGE,
} from '../actionTypes';
import { getEventList } from '../../requests/requests';

const eventsRequestSucceeded = (eventList) => ({
  type: EVENTS_SUCCESS,
  eventList,
});

const eventsRequestFailed = (notification) => ({
  type: EVENTS_FAILURE,
  notification,
});

const setEventsPerPage = (perPage) => ({
  type: EVENTS_PER_PAGE,
  perPage,
});

const setEventsPageCount = (pageCount) => ({
  type: EVENTS_PAGE_COUNT,
  pageCount,
});

export const setEventsCurrentPage = (currentPage) => ({
  type: EVENTS_CURRENT_PAGE,
  currentPage,
});

export const eventsAPI = () => (dispatch, getState) => {
  const {
    eventList,
    perPage,
    pageCount,
    currentPage,
    isReady,
  } = { ...getState().events };

  if (!perPage && !pageCount && !currentPage && !isReady) {
    getEventList(3, 1)
      .then((response) => {
        dispatch(eventsRequestSucceeded({ 1: response.data.events }));
        dispatch(setEventsPerPage(3));
        dispatch(setEventsPageCount(response.data.page_count));
        dispatch(setEventsCurrentPage(1));
      })
      .catch((error) => dispatch(eventsRequestFailed(error.message)))
      .finally(() => {});
  } else if (!eventList[currentPage]) {
    getEventList(perPage, currentPage)
      .then((response) => {
        const newState = {
          ...eventList,
          [`${currentPage}`]: response.data.events,
        };

        dispatch(eventsRequestSucceeded(newState));
      })
      .catch((error) => dispatch(eventsRequestFailed(error.message)))
      .finally(() => {});
  }
};
