/* eslint-disable default-param-last */
import { MANAGERS_SUCCESS, MANAGERS_FAILURE } from '../actionTypes';

const initialState = {
  managerData: [],
  isReady: false,
  notification: 'manager_list_loading',
};

const managersReducer = (state = initialState, action) => {
  switch (action.type) {
    case MANAGERS_SUCCESS:
      return {
        ...state,
        managerData: action.managerData,
        isReady: true,
        notification: '',
      };
    case MANAGERS_FAILURE:
      return {
        ...state,
        managerData: [],
        isReady: false,
        notification: action.notification,
      };
    default:
      return state;
  }
};

export default managersReducer;
