/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { getSearchResultsByHashtag, getSearchResultsByWords } from '../../../requests/requests';
import {
  setSearchQuery,
  searchResultsRequestSucceeded,
  searchResultsRequestFailed,
  setSearchResultsPerPage,
  setSearchResultsPageCount,
  setSearchResultsCurrentPage,
} from '../../../store/actions/searchResultsActions';
import search from '../../../assets/icons/actions/search.svg';
import OrdinaryInput from '../../ReusableElements/FormElemets/OrdinaryInput/OrdinaryInput';
import './SearchForm.scss';

export default function SearchForm({
  className,
  hideSearchForm,
}) {
  const { t, i18n } = useTranslation('header');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showSearchResults = (values, actions) => {
    const formData = { ...values };

    formData.search = formData.search.trim();

    if (formData.search && formData.search !== '#') {
      let searchRequest;

      if (formData.search[0] === '#') {
        searchRequest = getSearchResultsByHashtag;
      } else {
        searchRequest = getSearchResultsByWords;
      }

      searchRequest(formData.search, 6, 1)
        .then((response) => {
          if (response.status === 200) {
            actions.resetForm({
              touched: {},
              submitCount: 0,
            });

            hideSearchForm();
            navigate(
              i18n.languages[0] === 'en'
                ? '/search_results/en/'
                : '/search_results/',
            );
            dispatch(setSearchQuery(formData.search));
            // dispatch(searchResultsRequestSucceeded({ 1: response.data.search_results }));
            dispatch(searchResultsRequestSucceeded({ 1: response.data }));
            dispatch(setSearchResultsPerPage(6));
            dispatch(setSearchResultsPageCount(response.data.page_count));
            dispatch(setSearchResultsCurrentPage(1));
          }

          console.log(response.data);
        })
        .catch((error) => {
          dispatch(searchResultsRequestFailed(error.message));

          console.log(error.message);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    }
  };

  return (
    <Formik
      initialValues={{
        search: '',
      }}
      onSubmit={showSearchResults}
    >
      {() => (
        <Form className={className}>
          <button type="submit">
            <img
              src={search}
              alt="search"
            />
          </button>
          <OrdinaryInput
            placeholder={t('search')}
            name="search"
            type="search"
          />
        </Form>
      )}
    </Formik>
  );
}
