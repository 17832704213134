/* eslint-disable default-param-last */
import {
  COMPANY_PROFILE_SUCCESS,
  COMPANY_PROFILE_FAILURE,
  UPDATE_COMPANY_PROFILE,
} from '../actionTypes';

const initialState = {
  companyProfileData: {},
  isReady: false,
  notification: 'company_profile_data_loading',
};

const companyProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPANY_PROFILE_SUCCESS:
      return {
        ...state,
        companyProfileData: action.companyProfileData,
        isReady: true,
        notification: '',
      };
    case COMPANY_PROFILE_FAILURE:
      return {
        ...state,
        companyProfileData: {},
        isReady: false,
        notification: action.notification,
      };
    case UPDATE_COMPANY_PROFILE:
      return {
        ...state,
        companyProfileData: action.updatedCompanyProfileData,
      };
    default:
      return state;
  }
};

export default companyProfileReducer;
