import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { sendContactForm } from '../../../requests/requests';
import { showInfoMessage } from '../../../store/actions/modalWindowsActions';
import { subjectsOfRequest } from '../../../constants/optionsOfSelects';
import SelectElement from '../../ReusableElements/FormElemets/SelectElement/SelectElement';
import OrdinaryInput from '../../ReusableElements/FormElemets/OrdinaryInput/OrdinaryInput';
import PhoneInputElement from '../../ReusableElements/FormElemets/PhoneInputElement/PhoneInputElement';
import Submit from '../../ReusableElements/Buttons/Submit/Submit';
import InfoMessage from '../../ModalWindows/InfoMessage/InfoMessage';
import './ContactForm.scss';

export default function ContactForm() {
  const { t } = useTranslation(['form_error_messages', 'selects', 'form_fields', 'buttons', 'info_messages']);
  const [selectedSubjectOfRequest, setSelectedSubjectOfRequest] = useState(null);
  const [minPhoneLengthLimit, setMinPhoneLengthLimit] = useState();
  const [requiredPhoneLength, setRequiredPhoneLength] = useState();
  const [currentDialCode, setCurrentDialCode] = useState('380');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('+380');
  const dispatch = useDispatch();
  const {
    isInfoMessageVisible,
    contentKey,
  } = useSelector((state) => state.modalWindows.infoMessage);

  const writeToUsSchema = Yup.object({
    subject_of_request: Yup
      .string()
      .nonNullable(t('contact_form.subject_of_request.non_nullable')),
    full_name: Yup
      .string()
      .required(t('contact_form.full_name.required'))
      .matches(/^[a-zа-щєіїьюя'"`\s-]+$/i, t('contact_form.full_name.matches'))
      .min(2, t('contact_form.full_name.min')),
    phone: Yup
      .string()
      .min(minPhoneLengthLimit + 2, t('contact_form.phone.min'))
      .length(requiredPhoneLength + 1, t('contact_form.phone.length')),
    email: Yup
      .string()
      .required(t('contact_form.email.required'))
      .email(t('contact_form.email.email')),
  });

  const transform = (enteredValue) => {
    const trimmedValue = enteredValue
      .replace(/^['"`\s-]+/, '')
      .replace(/['"`\s-]+$/, '');
    const valueWithDeletedExtraCharacters = trimmedValue
      .replace(/[a-zа-щєіїьюя]['"`\s-]*\s+['"`\s-]*[a-zа-щєіїьюя]/gi, (str) => `${str[0]} ${str[str.length - 1]}`)
      .replace(/['"`-]+/g, (str) => str[0]);
    const partsOfValue = valueWithDeletedExtraCharacters.split(' ');
    const valueForRegistration = partsOfValue
      .map((str) => str[0].toUpperCase() + str.slice(1).toLowerCase())
      .join(' ')
      .replace(/-[a-zа-щєіїьюя]/gi, (str) => str.toUpperCase());

    return valueForRegistration;
  };

  const sendRequest = (values, actions) => {
    const formData = { ...values };

    formData.full_name = transform(formData.full_name);
    formData.email = formData.email.toLowerCase();
    formData.theme = formData.subject_of_request;

    delete formData.subject_of_request;

    sendContactForm(formData)
      .then((response) => {
        if (response.status === 200) {
          actions.resetForm({
            touched: {},
            submitCount: 0,
          });

          setSelectedSubjectOfRequest(null);
          setCurrentDialCode('380');
          setFormattedPhoneNumber('+380');
          dispatch(showInfoMessage('contact_form'));
        }

        console.log(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const subjects = subjectsOfRequest.map(({ value, labelKey }) => ({
    value,
    label: t(`selects:subjects_of_request.${labelKey}`),
  }));

  const selectTypeOfRequest = (selectedOption, formik) => {
    formik.setFieldTouched('subject_of_request');
    formik.setFieldValue('subject_of_request', selectedOption && selectedOption.value);

    setSelectedSubjectOfRequest(selectedOption);
  };

  const setPhoneParameters = (minLengthLimit, requiredLength) => {
    setMinPhoneLengthLimit(minLengthLimit);
    setRequiredPhoneLength(requiredLength);
  };

  return (
    <>
      <Formik
        initialValues={{
          subject_of_request: null,
          full_name: '',
          phone: '+380',
          email: '',
        }}
        validationSchema={writeToUsSchema}
        onSubmit={sendRequest}
      >
        {(formik) => (
          <Form className="contact-form">
            <div className="contact-form-fields">
              <SelectElement
                label={t('selects:subjects_of_request.label')}
                placeholder={t('selects:subjects_of_request.placeholder')}
                name="subject_of_request"
                value={selectedSubjectOfRequest}
                options={subjects}
                onChange={(selectedOption) => selectTypeOfRequest(selectedOption, formik)}
                isClearable
              />
              <OrdinaryInput
                label={t('form_fields:contact_form.full_name.label')}
                placeholder={t('form_fields:contact_form.full_name.placeholder')}
                name="full_name"
                type="text"
                maxLength={40}
              />
              <PhoneInputElement
                label={t('form_fields:contact_form.phone.label')}
                placeholder={t('form_fields:contact_form.phone.placeholder')}
                name="phone"
                type="tel"
                setPhoneParameters={setPhoneParameters}
                currentDialCode={currentDialCode}
                setCurrentDialCode={setCurrentDialCode}
                formattedPhoneNumber={formattedPhoneNumber}
                setFormattedPhoneNumber={setFormattedPhoneNumber}
              />
              <OrdinaryInput
                label={t('form_fields:contact_form.email.label')}
                placeholder={t('form_fields:contact_form.email.placeholder')}
                name="email"
                type="email"
              />
            </div>
            <Submit
              priority="primary"
              isDisabled={formik.isSubmitting}
            >
              {t('buttons:send')}
            </Submit>
          </Form>
        )}
      </Formik>
      {
        isInfoMessageVisible && contentKey === 'contact_form' && (
        <InfoMessage>
          {t('info_messages:contact_form')}
        </InfoMessage>
        )
      }
    </>
  );
}
