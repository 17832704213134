/* eslint-disable default-param-last */
import {
  COMPANIES_SUCCESS,
  COMPANIES_FAILURE,
  COMPANIES_PER_PAGE,
  COMPANIES_PAGE_COUNT,
  COMPANIES_CURRENT_PAGE,
} from '../actionTypes';

const initialState = {
  companyList: {},
  perPage: null,
  pageCount: null,
  currentPage: null,
  isReady: false,
  notification: 'company_list_loading',
};

const companiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPANIES_SUCCESS:
      return {
        ...state,
        companyList: action.companyList,
        isReady: true,
        notification: '',
      };
    case COMPANIES_FAILURE:
      return {
        ...state,
        companyList: {},
        perPage: null,
        pageCount: null,
        currentPage: null,
        isReady: false,
        notification: action.notification,
      };
    case COMPANIES_PER_PAGE:
      return {
        ...state,
        perPage: action.perPage,
      };
    case COMPANIES_PAGE_COUNT:
      return {
        ...state,
        pageCount: action.pageCount,
      };
    case COMPANIES_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage,
      };
    default:
      return state;
  }
};

export default companiesReducer;
