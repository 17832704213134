/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import './CommunityIndicatorItem.scss';

export default function CommunityIndicatorItem({ data }) {
  const { t } = useTranslation('landing_page', { keyPrefix: 'community.community_indicators' });

  return (
    <div className="community-indicator-list__item">
      <p className="community-indicator-list__value">
        {data.indicator}
      </p>
      <p className="community-indicator-list__title">
        {t(data.titleKey)}
      </p>
    </div>
  );
}
