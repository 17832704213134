/* eslint-disable default-param-last */
import {
  SEARCH_QUERY,
  SEARCH_RESULTS_SUCCESS,
  SEARCH_RESULTS_FAILURE,
  SEARCH_RESULTS_PER_PAGE,
  SEARCH_RESULTS_PAGE_COUNT,
  SEARCH_RESULTS_CURRENT_PAGE,
} from '../actionTypes';

const initialState = {
  searchQuery: null,
  searchResultsList: {},
  perPage: null,
  pageCount: null,
  currentPage: null,
  isReady: false,
  notification: 'search_results_list_loading',
};

const searchResultsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.searchQuery,
      };
    case SEARCH_RESULTS_SUCCESS:
      return {
        ...state,
        searchResultsList: action.searchResultsList,
        isReady: true,
        notification: '',
      };
    case SEARCH_RESULTS_FAILURE:
      return {
        ...state,
        searchResultsList: {},
        perPage: null,
        pageCount: null,
        currentPage: null,
        isReady: false,
        notification: action.notification,
      };
    case SEARCH_RESULTS_PER_PAGE:
      return {
        ...state,
        perPage: action.perPage,
      };
    case SEARCH_RESULTS_PAGE_COUNT:
      return {
        ...state,
        pageCount: action.pageCount,
      };
    case SEARCH_RESULTS_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage,
      };
    default:
      return state;
  }
};

export default searchResultsReducer;
