import { combineReducers } from 'redux';
import modalWindows from './modalWindowsReducer';
import menus from './menusReducer';
import searchResults from './searchResultsReducer';
import articles from './articlesReducer';
import article from './articleReducer';
import services from './servicesReducer';
import events from './eventsReducer';
import event from './eventReducer';
import managers from './managersReducer';
import companies from './companiesReducer';
import vipCompanies from './vipCompaniesReducer';
import companyProfile from './companyProfileReducer';

const rootReducer = combineReducers({
  modalWindows,
  menus,
  searchResults,
  articles,
  article,
  services,
  events,
  event,
  managers,
  companies,
  vipCompanies,
  companyProfile,
});

export default rootReducer;
