/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { getAdvertisings } from '../../../../requests/requests';
import AdvertisingItem from '../AdvertisingItem/AdvertisingItem';
import './AdvertisingList.scss';

export default function AdvertisingList() {
  const [advertisingData, setAdvertisingData] = useState();

  useEffect(() => {
    getAdvertisings()
      .then((response) => {
        console.log(response.data);
        setAdvertisingData(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  const onAddHandler = () => {
    const newAdvertising = { title: '', description: '', url: '' };
    const newAdvertisingData = [newAdvertising, ...advertisingData];
    setAdvertisingData(newAdvertisingData);
  };

  return (
    <section className="AdvertisingList">
      <button type="button" className="admin_button" onClick={onAddHandler}>Add</button>
      <table className="advertising-table">
        <thead>
          <tr>
            <th>ID:</th>
            <th>Назва:</th>
            <th>Опис:</th>
            <th>URL:</th>
            <th>image:</th>
            <th aria-label="empty" />
            <th aria-label="empty" />
          </tr>
        </thead>
        <tbody>
          { advertisingData && advertisingData.map((advertising) => (
            <AdvertisingItem
              data={advertising}
            />
          ))}
        </tbody>
      </table>
    </section>
  );
}
