import {
  SHOW_HOTLINE,
  HIDE_HOTLINE,
  SHOW_INFO_MESSAGE,
  HIDE_INFO_MESSAGE,
  SHOW_REGISTRATION,
  HIDE_REGISTRATION,
  SHOW_LOG_IN,
  HIDE_LOG_IN,
  SHOW_UPLOAD_FILES,
  HIDE_UPLOAD_FILES,
} from '../actionTypes';

export const showHotline = () => ({
  type: SHOW_HOTLINE,
});

export const hideHotline = () => ({
  type: HIDE_HOTLINE,
});

export const showInfoMessage = (contentKey) => ({
  type: SHOW_INFO_MESSAGE,
  contentKey,
});

export const hideInfoMessage = () => ({
  type: HIDE_INFO_MESSAGE,
});

export const showRegistration = (resetPasswordToken, email) => ({
  type: SHOW_REGISTRATION,
  resetPasswordToken,
  email,
});

export const hideRegistration = () => ({
  type: HIDE_REGISTRATION,
});

export const showLogIn = () => ({
  type: SHOW_LOG_IN,
});

export const hideLogIn = () => ({
  type: HIDE_LOG_IN,
});

export const showUploadFiles = () => ({
  type: SHOW_UPLOAD_FILES,
});

export const hideUploadFiles = () => ({
  type: HIDE_UPLOAD_FILES,
});
