/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Button from '../../ReusableElements/Buttons/Button/Button';
import CallOrderForm from '../../CallOrderForm/CallOrderForm';
import './Expertise.scss';

export default function Expertise() {
  const { t, i18n } = useTranslation(['service_pages', 'buttons']);

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant',
    });
  }, []);
  const ldJson = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Івано-Франківська торгово-промислова палата',
    url: 'https://cci.if.ua/',
    logo: 'https://cci.if.ua/static/media/logo_small.088e05e0446873d0c8dfb27546b82194.svg',
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+380342523347',
      contactType: 'customer service',
      areaServed: 'UA',
      availableLanguage: 'Ukrainian',
    },
    sameAs: [
      'https://www.facebook.com/cci.if.ua/',
      'https://www.instagram.com/if.cci.ua/',
    ],
  };
  return (
    <section className="Expertise">
      <Helmet>
        <title>
          Експертиза товарів та обладнання: визначення кодів, якість,
          вартість | Івано-Франківська Торгово-промислова палата
        </title>
        <meta
          name="description"
          content="Проведення експертизи товарів та обладнання,
          включаючи визначення кодів УКТЗЕД та ДКПП, оцінку якості,
          вартості іноземних товарів та автомобільного транспорту.
          Замовлення експертної документації для реєстрації ТМ.
          Завантаження бланків та перегляд зразків документів онлайн."
        />
        <script type="application/ld+json">
          {JSON.stringify(ldJson)}
        </script>
      </Helmet>
      <div className="wrapper">
        <div className="expertise-content">
          <div className="expertise-content-top-row">
            <a
              className="previous-page-link"
              href={
                i18n.languages[0] === 'en'
                  ? '/en/'
                  : '/'
              }
            >
              {t('previous_page_title')}
            </a>
            <div className="expertise-content-top-row__right-side">
              <h1 className="heading-one">
                {t('heading.expertise')}
              </h1>
              <p className="description">
                {t('description')}
              </p>
              <div className="types-of-service">
                <ul>
                  <li>
                    товарів та обладнання;
                  </li>
                  <li>
                    визначення кодів товарів УКТЗЕД та ДКПП;
                  </li>
                  <li>
                    якості продукції і маси товару;
                  </li>
                  <li>
                    вартості іноземних товарів;
                  </li>
                  <li>
                    прайсу виробника;
                  </li>
                  <li>
                    вартості автомобільного транспорту;
                  </li>
                  <li>
                    підготовка документів для реєстрації ТМ.
                  </li>
                </ul>
              </div>
              <div className="buttons">
                <Button
                  priority="primary"
                  link="#"
                  download
                >
                  {t('buttons:download_the_form')}
                </Button>
                <Button
                  priority="secondary"
                  link="#"
                  target="_blank"
                >
                  {t('buttons:view_the_sample')}
                </Button>
              </div>
              <div className="list-of-required-documents">
                <p className="paragraph">
                  Для проведення експертиз по визначенню кодів товару згідно з Українською класифікацією товарів
                  зовнішньо-економічної діяльності (УКТЗЕД) Замовник разом із заявкою надає відповідний пакет
                  документів (оригінали та/або належним чином завірені копії), який має включати всю документальну
                  інформацію про характеристики товару, визначальні для його класифікації, у т.ч.:
                </p>
                <ul>
                  <li>
                    <span className="bold-font">
                      договір (контракт)
                    </span>
                    {' '}
                    на поставку товару з додатками й специфікаціями;
                  </li>
                  <li>
                    <span className="bold-font">
                      товаросупровідні та товаротранспортні документи
                    </span>
                    , які містять інформацію про найменування товару, його торгову назву, марку, артикул, розміри,
                    розфасовку, вид упаковки, маркування та інше (рахунок-фактура, інвойс, відвантажувальна
                    специфікація тощо);
                  </li>
                  <li>
                    <span className="bold-font">
                      техніко-технологічні документи
                    </span>
                    , що містять інформацію про технічні, фізичні та споживчі характеристики товару, технологію
                    виробництва, хімічний склад, матеріал, з якого виготовлено товар, його призначення, функції,
                    принцип дії, ступінь обробки, комплектність та інше (нормативна та технічна документація – ГОСТ,
                    ДСТУ, ТУ тощо, технологічний регламент, технічний опис, паспорт, інструкція, керівництво з
                    експлуатації, результати лабораторних випробувань, специфікації, сертифікати якості, відповідності,
                    безпеки, каталоги, креслення, фотознімки тощо);
                  </li>
                  <li>
                    <span className="bold-font">
                      інші документи
                    </span>
                    {' '}
                    або будь-яку іншу інформацію про товар, які необхідні для однозначної ідентифікації товару та
                    прийняття рішення по визначенню коду.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <h2 className="heading-two heading-two_form">
            {t('call_order_form_heading')}
          </h2>
          <p className="description">
            {t('call_order_form_description')}
          </p>
          <CallOrderForm />
        </div>
      </div>
    </section>
  );
}
