/* eslint-disable camelcase */
import axiosInstance from './axiosInstance';

export const sendHotlineForm = (formData) => axiosInstance.post('/hotline_forms', {
  hotline_form: formData,
});

export const finishRegistration = (formData) => axiosInstance.patch('/users/finish_registration', {
  user: formData,
});

export const userLogIn = (formData) => axiosInstance.post('/users/log_in', {
  user: formData,
});

export const userLogOut = () => axiosInstance.delete('/users/log_out');

export const getSearchResultsByHashtag = (searchHashtag, perPage, currentPage) => axiosInstance.get('/articles/search_by_hashtag', {
  params: {
    search_request: searchHashtag,
    per_page: perPage,
    current_page: currentPage,
  },
});

export const getSearchResultsByWords = (searchWords, perPage, currentPage) => axiosInstance.get('/articles/search_by_words', {
  params: {
    search_request: searchWords,
    per_page: perPage,
    current_page: currentPage,
  },
});

export const getArticleList = (perPage, currentPage) => axiosInstance.get('/articles', {
  params: {
    per_page: perPage,
    current_page: currentPage,
  },
});

export const getArticleItem = (articleId) => axiosInstance.get(`/articles/${articleId}`);

export const sendArticleCommentForm = (formData) => axiosInstance.post('/article_comment_forms', {
  article_comment_form: formData,
});

export const getServices = () => axiosInstance.get('/services');

export const sendCallOrderForm = (formData) => axiosInstance.post('/call_order_forms', {
  call_order_form: formData,
});

export const getEventList = (perPage, currentPage) => axiosInstance.get('/events', {
  params: {
    per_page: perPage,
    current_page: currentPage,
  },
});

export const getEventItem = (eventId) => axiosInstance.get(`/events/${eventId}`);

export const getManagers = () => axiosInstance.get('/managers');

export const getCompanyList = (perPage, currentPage) => axiosInstance.get('/companies', {
  params: {
    per_page: perPage,
    current_page: currentPage,
  },
});

export const getVIPCompanies = () => axiosInstance.get('/companies/vip');

export const sendContactForm = (formData) => axiosInstance.post('/contact_forms', {
  contact_form: formData,
});

export const getCompanyProfileData = () => axiosInstance.get('/users/company_profile_data');

export const updateCompanyProfileData = (userId, formData) => axiosInstance.patch(`/users/${userId}`, {
  user: formData,
});

export const sendProposalForm = (formData) => axiosInstance.post('/proposal_forms', {
  proposal_form: formData,
});

export const sendInitiativeForm = (formData) => axiosInstance.post('/initiative_forms', {
  initiative_form: formData,
});

// start requests for adminpanel

export const getUsers = () => axiosInstance.get('/adminpanel/users');

export const getArticles = () => axiosInstance.get('/adminpanel/articles');

export const getEvents = () => axiosInstance.get('/adminpanel/events');

export const getCompanies = () => axiosInstance.get('/adminpanel/companies');

export const getSliders = () => axiosInstance.get('/adminpanel/landing_items');

export const getAdvertisings = () => axiosInstance.get('/adminpanel/advertisings');

export const postArticle = ({
  article: {
    title, description, body, articleable_type, articleable_id,
  },
}) => axiosInstance.post('/adminpanel/articles', {
  title,
  description,
  body,
  articleable_type,
  articleable_id,
});

export const postAdvertising = (formData) => axiosInstance.post('/adminpanel/advertisings', formData);

export const deleteAdvertising = (id) => axiosInstance.delete(`/adminpanel/advertisings/${id}`);

export const updateAdvertising = (id, formData) => axiosInstance.patch(`/adminpanel/advertisings/${id}`, formData);

export const changeArticleState = (id) => axiosInstance.post('/adminpanel/articles/change_state', { id });

export const postArticleCover = (id, image) => axiosInstance.post(
  '/adminpanel/articles/load_file',
  {
    id,
    image,
    attachment_type: 'cover',
  },
  {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  },
);

export const postArticleImages = (id, image) => axiosInstance.post(
  '/adminpanel/articles/load_file',
  {
    id,
    image,
    attachment_type: 'images',
  },
  {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  },
);

export const postAdvertisingImage = (id, image) => axiosInstance.post(
  '/adminpanel/advertisings/load_file',
  {
    id,
    image,
    attachment_type: 'image',
  },
  {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  },
);
export const deleteManager = (id) => axiosInstance.delete(`/adminpanel/managers/${id}`);
export const updateManager = (id, formData) => axiosInstance.patch(`/adminpanel/managers/${id}`, formData);
export const postManager = (formData) => axiosInstance.post('/adminpanel/managers', formData);
export const postUserImages = (id, image) => axiosInstance.post(
  '/adminpanel/users/load_file',
  {
    id,
    image,
    attachment_type: 'image',
  },
  {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  },
);
export const postAddsImages = (addId, image) => axiosInstance.post(
  '/adminpanel/advertisings/load_file',
  {
    id: addId.children,
    image,
    attachment_type: 'image',
  },
  {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  },
);
// end requests for adminpanel
