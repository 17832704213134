import {
  SHOW_HAMBURGER_MENU,
  HIDE_HAMBURGER_MENU,
  SHOW_MAIN_DROPDOWN_LIST,
  HIDE_MAIN_DROPDOWN_LIST,
  SHOW_DOCUMENTS_DROPDOWN_LIST,
  HIDE_DOCUMENTS_DROPDOWN_LIST,
  SHOW_PROPOSALS_FOR_CCI_DROPDOWN_LIST,
  HIDE_PROPOSALS_FOR_CCI_DROPDOWN_LIST,
  SHOW_SEARCH_FORM,
  HIDE_SEARCH_FORM,
  SHOW_LANGUAGE_DROPDOWN_LIST,
  HIDE_LANGUAGE_DROPDOWN_LIST,
} from '../actionTypes';

export const showHamburgerMenu = () => ({
  type: SHOW_HAMBURGER_MENU,
});

export const hideHamburgerMenu = () => ({
  type: HIDE_HAMBURGER_MENU,
});

export const showMainDropdownList = () => ({
  type: SHOW_MAIN_DROPDOWN_LIST,
});

export const hideMainDropdownList = () => ({
  type: HIDE_MAIN_DROPDOWN_LIST,
});

export const showDocumentsDropdownList = () => ({
  type: SHOW_DOCUMENTS_DROPDOWN_LIST,
});

export const hideDocumentsDropdownList = () => ({
  type: HIDE_DOCUMENTS_DROPDOWN_LIST,
});

export const showProposalsForCCIDropdownList = () => ({
  type: SHOW_PROPOSALS_FOR_CCI_DROPDOWN_LIST,
});

export const hideProposalsForCCIDropdownList = () => ({
  type: HIDE_PROPOSALS_FOR_CCI_DROPDOWN_LIST,
});

export const showSearchForm = () => ({
  type: SHOW_SEARCH_FORM,
});

export const hideSearchForm = () => ({
  type: HIDE_SEARCH_FORM,
});

export const showLanguageDropdownList = () => ({
  type: SHOW_LANGUAGE_DROPDOWN_LIST,
});

export const hideLanguageDropdownList = () => ({
  type: HIDE_LANGUAGE_DROPDOWN_LIST,
});
