/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch } from 'react-redux';
import { hideInfoMessage } from '../../../store/actions/modalWindowsActions';
import closeWhite from '../../../assets/icons/actions/close_white.svg';
import './InfoMessage.scss';

export default function InfoMessage({ children }) {
  const dispatch = useDispatch();

  const closeModalWindow = () => {
    dispatch(hideInfoMessage());
  };

  return (
    <div className="info-message">
      <div className="modal-window">
        <p className="paragraph">
          {children}
        </p>
        <button
          className="close"
          type="button"
          onClick={closeModalWindow}
        >
          <img
            src={closeWhite}
            alt="closeWhite"
          />
        </button>
      </div>
    </div>
  );
}
