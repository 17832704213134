import React from 'react';
import { useTranslation } from 'react-i18next';
import phoneGreen from '../../../assets/icons/info/phone_green.svg';
import email from '../../../assets/icons/info/email.svg';
import website from '../../../assets/icons/info/website.svg';
import facebook from '../../../assets/icons/social-networks/facebook.svg';
import instagram from '../../../assets/icons/social-networks/instagram.svg';
import UNDP from '../../../assets/images/logos/donors/UNDP.png';
import Swiss from '../../../assets/images/logos/donors/Swiss.png';
import './Footer.scss';

export default function Footer() {
  const { t, i18n } = useTranslation('footer');

  const availableWindowWidth = document.documentElement.clientWidth;

  const leftSide = (
    <>
      {
        availableWindowWidth > 1280
          ? (
            <>
              <p className="copyright">
                {t('copyright')}
              </p>
              <p className="address">
                {t('address')}
              </p>
            </>
          )
          : (
            <div className="grouped-elements">
              <p className="copyright">
                {t('copyright')}
              </p>
              <p className="address">
                {t('address')}
              </p>
            </div>
          )
      }
      <div className="contact-list">
        <div className="contact-list__item">
          <img
            className="info-icon"
            src={phoneGreen}
            alt="phoneGreen"
          />
          <a
            href="tel:+380342523347"
          >
            0342 52 33 47
          </a>
        </div>
        <div className="contact-list__item">
          <img
            className="info-icon"
            src={email}
            alt="email"
          />
          <a
            href="mailto:info@cci.if.ua"
          >
            info@cci.if.ua
          </a>
        </div>
        <div className="contact-list__item">
          <img
            className="info-icon"
            src={website}
            alt="website"
          />
          <a
            href={
              i18n.languages[0] === 'en'
                ? '/en/'
                : '/'
            }
          >
            https://cci.if.ua
          </a>
        </div>
      </div>
      <div className="social-networks">
        <a
          href="https://www.facebook.com/cci.if.ua/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={facebook}
            alt="facebook"
          />
        </a>
        <a
          href="https://www.instagram.com/if.cci.ua/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={instagram}
            alt="instagram"
          />
        </a>
      </div>
    </>
  );
  const rightSide = (
    <>
      <p className="disclaimer">
        {t('disclaimer')}
      </p>
      <div className="donors">
        <a
          href={
            i18n.languages[0] === 'en'
              ? 'https://www.undp.org/ukraine'
              : 'https://www.undp.org/uk/ukraine'
          }
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={UNDP}
            alt="UNDP"
          />
        </a>
        <a
          href={
            i18n.languages[0] === 'en'
              ? 'https://www.eda.admin.ch/countries/ukraine/en/home/representations/embassy-in-kiev.html'
              : 'https://www.eda.admin.ch/countries/ukraine/uk/home/predstavnictva/%D0%BF%D0%BE%D1%81%D0%BE%D0%BB%D1%8C%D1%81%D1%82%D0%B2%D0%BE-%D1%83-%D0%BA%D0%B8%D1%94%D0%B2%D1%96.html'
          }
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={Swiss}
            alt="Swiss"
          />
        </a>
      </div>
    </>
  );

  return (
    <footer className="Footer">
      <div className="wrapper">
        <div className="footer-content">
          {
            availableWindowWidth > 834
              ? (
                <>
                  {leftSide}
                  {rightSide}
                </>
              )
              : (
                <>
                  <div className="footer-content-top-row">
                    {leftSide}
                  </div>
                  <div className="footer-content-bottom-row">
                    {rightSide}
                  </div>
                </>
              )
          }
        </div>
      </div>
    </footer>
  );
}
