import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import { showLanguageDropdownList, hideLanguageDropdownList } from '../../../store/actions/menusActions';
import chevron from '../../../assets/icons/chevron.svg';
import './LanguageSwitcher.scss';

export default function LanguageSwitcher() {
  const lngs = [
    {
      value: 'uk',
      label: 'UA',
    },
    {
      value: 'en',
      label: 'ENG',
    },
  ];
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.languages[0] === 'en' ? 'en' : 'uk');
  const dispatch = useDispatch();
  const { isLanguageDropdownListVisible } = useSelector((state) => state.menus);

  const toggleLanguageDropdownList = () => {
    if (isLanguageDropdownListVisible) {
      dispatch(hideLanguageDropdownList());
    } else {
      dispatch(showLanguageDropdownList());
    }
  };

  const getCurrentLanguage = () => lngs.find((lng) => lng.value === currentLanguage).label;

  const selectLanguage = (e) => {
    const { value } = e.target.dataset;

    i18n.changeLanguage(value, () => {
      setCurrentLanguage(value);
      dispatch(hideLanguageDropdownList());
    });
  };

  const getLanguageDropdownListItems = () => {
    const dropdownListItems = lngs.filter((lng) => lng.value !== currentLanguage);

    return dropdownListItems.map((dropdownListItem) => (
      <li
        key={uuidv4()}
        data-value={dropdownListItem.value}
        aria-hidden="true"
        onClick={selectLanguage}
      >
        {dropdownListItem.label}
      </li>
    ));
  };

  return (
    <div className={classNames('language-switcher', { active: isLanguageDropdownListVisible })}>
      <button
        type="button"
        onClick={toggleLanguageDropdownList}
      >
        <span>
          {getCurrentLanguage()}
        </span>
        <img
          src={chevron}
          alt="chevron"
        />
      </button>
      {
        isLanguageDropdownListVisible && (
        <ul>
          {getLanguageDropdownListItems()}
        </ul>
        )
      }
    </div>
  );
}
