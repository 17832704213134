/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { companiesAPI } from '../../store/actions/companiesActions';
import CompanyList from '../CompanyList/CompanyList';
import './CommunityPage.scss';

export default function CommunityPage() {
  const { t, i18n } = useTranslation(['community_page', 'notifications']);
  const [minimumPageRequiredHeight, setMinimumPageRequiredHeight] = useState();
  const dispatch = useDispatch();
  const {
    companyList,
    perPage,
    pageCount,
    currentPage,
    isReady,
    notification,
  } = useSelector((state) => state.companies);

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant',
    });

    dispatch(companiesAPI());
  }, [currentPage]);

  useEffect(() => {
    const header = document.querySelector('.Header.fixed');
    const communityPage = document.querySelector('.CommunityPage');
    const footer = document.querySelector('.Footer');
    const minimumRequiredHeight = document.documentElement.clientHeight - (header.offsetHeight + footer.offsetHeight);

    communityPage.style.minHeight = `${minimumRequiredHeight}px`;
    setMinimumPageRequiredHeight(minimumRequiredHeight);
  }, []);

  return (
    <section className="CommunityPage">
      <div className="wrapper">
        <div className="community-page-content">
          <div className="community-page-content-top-row">
            <a
              className="previous-page-link"
              href={
                i18n.languages[0] === 'en'
                  ? '/en/'
                  : '/'
              }
            >
              {t('previous_page_title')}
            </a>
            <h1 className="heading-one">
              {t('heading')}
            </h1>
          </div>
          {
            perPage && pageCount !== null && currentPage && isReady
              ? (
                <CompanyList
                  companyList={companyList}
                  pageCount={pageCount}
                  currentPage={currentPage}
                  minimumPageRequiredHeight={minimumPageRequiredHeight}
                />
              )
              : (
                <p className="notification">
                  {t(`notifications:${notification}`, notification)}
                </p>
              )
          }
        </div>
      </div>
    </section>
  );
}
