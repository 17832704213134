/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CompanyProfileForm from '../../CompanyProfileForm/CompanyProfileForm';
import './CompanyProfile.scss';

export default function CompanyProfile() {
  const { t } = useTranslation('personal_account', { keyPrefix: 'company_profile' });

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant',
    });
  }, []);

  useEffect(() => {
    const header = document.querySelector('.Header.fixed');
    const companyProfile = document.querySelector('.CompanyProfile');
    const footer = document.querySelector('.Footer');
    const minimumRequiredHeight = document.documentElement.clientHeight - (header.offsetHeight + footer.offsetHeight);

    companyProfile.style.minHeight = `${minimumRequiredHeight}px`;
  }, []);

  return (
    <section className="CompanyProfile">
      <div className="wrapper">
        <div className="company-profile-content">
          <h1 className="heading-one">
            {t('heading')}
          </h1>
          <CompanyProfileForm />
        </div>
      </div>
    </section>
  );
}
